import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../shadcn-components/card";
import { Button } from "../../../shadcn-components/button";
import { Separator } from "../../../shadcn-components/separator";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import whatsappIcon from "../../../static/whatsapp.svg";
import { getCommunityLogo, getUserImage } from "../../../utils/image-utils";
import { AdminUser } from "../../../stores/auth.types";

interface HostDetailsProps {
  communityName: string;
  communityHandle: string;
  communityLogo?: string;
  admins: AdminUser[];
}
export const HostDetailsSection: FC<HostDetailsProps> = observer(
  ({ communityHandle, communityName, admins, communityLogo }) => {
    if (!admins || admins.length === 0) {
      return null;
    }
    const navigate = useNavigate();

    return (
      <div className="!tw-my-8">
        <h2 className="tw-text-base tw-font-bold tw-mb-3 tw-text-left">
          Host Details
        </h2>
        <Card className="tw-border-0 tw-shadow-none tw-bg-[#F1F5F9] !tw-mt-0">
          <CardHeader className="!tw-p-4 tw-pb-3">
            <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
              <div className="tw-flex tw-items-center tw-gap-2">
                <Avatar className="tw-w-6 tw-h-6">
                  {communityLogo && (
                    <AvatarImage
                      src={getCommunityLogo(communityLogo)}
                      alt={communityName}
                    />
                  )}
                  <AvatarFallback>{communityName?.[0]}</AvatarFallback>
                </Avatar>
                <span className="tw-text-sm tw-font-medium">
                  By {communityName}
                </span>
              </div>
              <Button
                variant="link"
                className="tw-text-blue-600 !tw-p-0 tw-h-auto tw-font-medium"
                onClick={() => navigate(`/${communityHandle}`)}
              >
                View Community
              </Button>
            </div>
          </CardHeader>
          <div className="tw-px-4">
            <Separator className="tw-bg-gray-200" />
          </div>
          <CardContent className="!tw-px-4 !tw-pt-4 !tw-pb-2">
            {admins.map((host, index) => (
              <div
                key={index}
                className="tw-flex tw-items-center tw-justify-between tw-mb-4"
              >
                <div className="tw-flex tw-items-center tw-gap-2">
                  <Avatar className="tw-w-6 tw-h-6">
                    {host.profilePicture && (
                      <AvatarImage
                        src={getUserImage(host.profilePicture)}
                        alt={host.accountName}
                      />
                    )}
                    <AvatarFallback>{host.accountName?.[0]}</AvatarFallback>
                  </Avatar>
                  <span className="tw-text-sm tw-font-semibold">
                    {host.accountName}
                  </span>
                </div>
                {host.phoneNumber && (
                  <Button className="!tw-bg-green-500 tw-text-white tw-rounded-full tw-py-2 tw-px-4">
                    <img
                      src={whatsappIcon}
                      className="tw-w-5 tw-h-5 tw-mr-2"
                      alt="WhatsApp"
                    />
                    Chat
                  </Button>
                )}
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }
);
