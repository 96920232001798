import { FC } from "react";
import googlePlayStoreBadge from "../static/GetItOnGooglePlay_Badge_Web_color_English.png";
import appStoreBadge from "../static/AppStoreBadge.svg";

export const DownloadCard: FC = () => (
  <section className="tw-max-w-md tw-mx-auto tw-bg-[#F1F5F9]">
  <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-gap-1 tw-px-8 tw-py-8">
    <h2 className="tw-text-2xl tw-font-bold">Get the Jackpass App</h2>
    <p className="tw-text-gray-600 tw-text-sm tw-mb-5">
      Explore your hobbies, find your tribe!
    </p>
    <div className="tw-flex tw-items-center tw-gap-4 tw-flex-nowrap">
      <a href="#" className="tw-relative tw-w-1/2">
        <img
          src={appStoreBadge}
          alt="Download on App Store"
          className="tw-h-12 tw-w-full tw-object-contain"
        />
      </a>
      <a href="#" className="tw-relative tw-w-1/2">
        <img
          src={googlePlayStoreBadge}
          alt="Get it on Google Play"
          className="tw-h-12 tw-w-full tw-object-contain"
        />
      </a>
    </div>
  </div>
</section>
);