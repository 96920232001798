import { observer } from "mobx-react-lite";
import { FC } from "react";
import { allInterests } from "../../utils/interests-utils";
import { Check } from "lucide-react";
import signUpStore from "../../stores/signup-store";

interface InterestSelectorProps {
  onChange: (categoryId: string) => void;
  value: string;
}

export const InterestSelector: FC<InterestSelectorProps> = observer(
  ({ onChange, value }) => {
    const { interestOptions } = signUpStore;
    const emoji = (name: string) =>
      allInterests.filter((i) => i.name === name)?.[0]?.emoji;
    return (
      <div>
        <div className="tw-flex tw-gap-4 tw-overflow-x-auto !tw-scrollbar-hide tw-pb-2">
          {interestOptions.map((category) => (
            <button
              key={category.id}
              onClick={() => onChange(category.id)}
              className={`
              tw-relative tw-flex tw-flex-col tw-items-center tw-rounded-[8px] tw-py-2 tw-bg-white tw-min-w-[98px] tw-min-h-[72px] 
              ${
                value === category.id
                  ? "tw-border-2 tw-border-[#1E28FF]"
                  : "tw-border tw-border-[#EDEDEB]"
              }
            `}
              type="button"
            >
              {value === category.id && (
                <div className="tw-absolute tw-top-2 tw-right-2">
                  <div className="tw-bg-[#1E28FF] tw-rounded-full tw-p-0.5">
                    <Check className="tw-w-4 tw-h-4 tw-text-white" />
                  </div>
                </div>
              )}
              <div className="tw-flex tw-items-center tw-justify-center">
                <span className="tw-text-[28px]">
                  {emoji(category.display_en)}
                </span>
              </div>
              <span
                className={`
                tw-text-xs
                ${
                  value === category.id
                    ? "tw-text-[#1E28FF] tw-font-medium"
                    : "tw-text-gray-600"
                }
              `}
              >
                {category.display_en}
              </span>
            </button>
          ))}
        </div>
      </div>
    );
  }
);
