type InterestName =
  | "Fitness"
  | "Startups"
  | "Food"
  | "Sports"
  | "Arts"
  | "Adventure"
  | "Travel"
  | "Indoors"
  | "Party";

export const interestNames: InterestName[] = [
  "Fitness",
  "Startups",
  "Food",
  "Sports",
  "Arts",
  "Adventure",
  "Travel",
  "Indoors",
  "Party",
];

export interface Interest {
  name: InterestName;
  emoji: string;
}

export const allInterests: Interest[] = [
  { name: "Fitness", emoji: "🏃‍♀️" },
  { name: "Startups", emoji: "🦄" },
  { name: "Food", emoji: "🍕" },
  { name: "Sports", emoji: "⚽" },
  { name: "Arts", emoji: "🎨" },
  { name: "Adventure", emoji: "🧗‍♂️" },
  { name: "Travel", emoji: "✈️" },
  { name: "Indoors", emoji: "♟️" },
  { name: "Party", emoji: "💃" },
];
