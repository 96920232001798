import { useEffect, useState } from "react";
import { Button } from "../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { manageCommunityStore } from "../../stores/manage-community.store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import {
  ManageCommunityFormValues,
  ManageCommunityImageState,
  manageCommunitySchema,
} from "./manage-community.schema";
import signUpStore from "../../stores/signup-store";
import { Input } from "../../shadcn-components/input";
import { Textarea } from "../../shadcn-components/textarea";
import { CommunityImageBanner } from "./community-banner-avatar-edit";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getCommunityBanner, getCommunityLogo } from "../../utils/image-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { InterestSelector } from "./category-selector";
import { SocialMediaInput } from "./social-media-fields";
import { SignUpUsernameField } from "../signup-username";
import { Toaster } from "../../shadcn-components/toaster";
type SocialMediaKeys = keyof ManageCommunityFormValues["socialMedia"];
export const ManageCommunityForm = observer(() => {
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [showingError, setShowingError] = useState(false);
  const {
    editCommunityData,
    isLoading,
    getManageFormData,
    updateField,
    usernameError,
  } = manageCommunityStore;

  useEffect(() => {
    if (
      !isLoading &&
      editCommunityData &&
      !editCommunityData.isLoggedInUserAdmin
    ) {
      // setShowingError(true);
      const redirectTimer = setTimeout(() => {
        navigate(`/${communityHandle}`);
      }, 2000);

      return () => clearTimeout(redirectTimer);
    } else if (!isLoading && editCommunityData) {
      setIsCheckingAuth(false);
    }
  }, [editCommunityData, isLoading, navigate, communityHandle]);

  useEffect(() => {
    if (!isLoading && editCommunityData) {
      setIsCheckingAuth(false);
    }
  }, [isLoading, editCommunityData]);
  const { getAllClientSideData } = signUpStore;
  const form = useForm<ManageCommunityFormValues>({
    resolver: zodResolver(manageCommunitySchema),
    defaultValues: {
      title: "",
      handle: "",
      description: "",
      location: "",
      categories: [],
      socialMedia: {
        twitter: "",
        instagram: "",
        youtube: "",
      },
      coverPhoto: null,
      profilePhoto: null,
    },
  });

  const [imageState, setImageState] = useState<ManageCommunityImageState>({
    coverPhotoUrl: null,
    profilePhotoUrl: null,
    coverPhotoFile: null,
    profilePhotoFile: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (communityHandle) {
        await getManageFormData(communityHandle);
        await getAllClientSideData();
      }
    };
    fetchData();
  }, [communityHandle, getManageFormData, getAllClientSideData]);

  useEffect(() => {
    if (editCommunityData) {
      form.reset({
        title: editCommunityData.title,
        handle: editCommunityData.handle,
        description: editCommunityData.description || "",
        location: editCommunityData.location || "",
        categories: editCommunityData.categories || [],
        socialMedia: editCommunityData.socialMedia || {},
        coverPhoto: editCommunityData.bannerImageUrls || null,
        profilePhoto: editCommunityData.profileImageUrl || null,
      });

      setImageState({
        coverPhotoUrl: editCommunityData.bannerImageUrls || null,
        profilePhotoUrl: editCommunityData.profileImageUrl || null,
        coverPhotoFile: null,
        profilePhotoFile: null,
      });
    }
  }, [editCommunityData, form]);

  const handleCoverPhotoChange = (file: File, previewUrl: string) => {
    setImageState((prev) => ({
      ...prev,
      coverPhotoUrl: previewUrl,
      coverPhotoFile: file,
    }));
    form.setValue("coverPhoto", file);
  };

  const handleProfilePhotoChange = (file: File, previewUrl: string) => {
    setImageState((prev) => ({
      ...prev,
      profilePhotoUrl: previewUrl,
      profilePhotoFile: file,
    }));
    form.setValue("profilePhoto", file);
  };

  useEffect(() => {
    return () => {
      if (imageState.coverPhotoUrl?.startsWith("blob:")) {
        URL.revokeObjectURL(imageState.coverPhotoUrl);
      }
      if (imageState.profilePhotoUrl?.startsWith("blob:")) {
        URL.revokeObjectURL(imageState.profilePhotoUrl);
      }
    };
  }, [imageState.coverPhotoUrl, imageState.profilePhotoUrl]);

  const onSubmit = async (formData: ManageCommunityFormValues) => {
    setIsSaving(true);
    try {
      const changes: Partial<ManageCommunityFormValues> = {};

      Object.entries(formData).forEach(([key, value]) => {
        const originalValue =
          editCommunityData?.[key as keyof typeof editCommunityData];

        // Skip null values for photo fields if there's no file change
        if (
          (key === "coverPhoto" || key === "profilePhoto") &&
          value === null &&
          !imageState[`${key}File` as keyof typeof imageState]
        ) {
          return;
        }

        // Handle social media comparison separately
        if (key === "socialMedia") {
          const originalSocial =
            originalValue as ManageCommunityFormValues["socialMedia"];
          const newSocial = value as ManageCommunityFormValues["socialMedia"];
          if (JSON.stringify(newSocial) !== JSON.stringify(originalSocial)) {
            changes.socialMedia = newSocial || {};
          }
          return;
        }

        if (JSON.stringify(value) !== JSON.stringify(originalValue)) {
          changes[key as keyof ManageCommunityFormValues] = value as any;
        }
      });

      // Only include image changes if there are actual file changes
      if (imageState.coverPhotoFile) {
        changes.coverPhoto = imageState.coverPhotoFile;
      }
      if (imageState.profilePhotoFile) {
        changes.profilePhoto = imageState.profilePhotoFile;
      }

      await updateField(changes);
      navigate(`/${formData.handle}`);
    } catch (error) {
      console.error("Error saving changes:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading || isCheckingAuth) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (showingError) {
    return (
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-screen tw-gap-2">
        <div className="tw-text-red-500 tw-font-medium">Access Denied</div>
        <div className="tw-text-gray-500">
          You must be an admin to manage this community
        </div>
        <div className="tw-text-gray-400 tw-text-sm">Redirecting...</div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen">
      <Toaster />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="tw-flex tw-flex-col tw-flex-1 tw-p-4"
        >
          <div className="tw-text-center tw-mb-2 tw-mt-4">
            <p className="tw-text-[#13131580] tw-font-medium tw-text-xs">
              Choose a cover photo that represents your
              <br />
              community's vibe on the listing screen
            </p>
          </div>

          <div className="tw-w-full tw-max-w-md tw-mx-auto">
            <CommunityImageBanner
              coverPhotoUrl={
                imageState.coverPhotoFile
                  ? imageState.coverPhotoUrl
                  : imageState.coverPhotoUrl
                  ? getCommunityBanner(imageState.coverPhotoUrl)
                  : ""
              }
              profilePhotoUrl={
                imageState.profilePhotoFile
                  ? imageState.profilePhotoUrl
                  : imageState.profilePhotoUrl
                  ? getCommunityLogo(imageState.profilePhotoUrl)
                  : ""
              }
              onCoverPhotoChange={handleCoverPhotoChange}
              onProfilePhotoChange={handleProfilePhotoChange}
            />
          </div>

          <div className="tw-space-y-6">
            {/* Category */}
            <FormField
              control={form.control}
              name="categories"
              render={({ field }) => (
                <FormItem className="tw-flex tw-flex-col">
                  <FormLabel
                    className={"tw-text-left tw-text-base tw-font-medium"}
                  >
                    Category
                    <span className="tw-text-red-500">*</span>
                  </FormLabel>
                  <InterestSelector
                    value={field.value?.[0] || ""}
                    onChange={(value) => {
                      form.setValue("categories", [value]);
                    }}
                  />
                  {form.formState.errors.categories && (
                    <span className="tw-text-red-500 tw-text-sm">
                      {form.formState.errors.categories.message}
                    </span>
                  )}
                  <FormMessage className="tw-text-red-500 tw-text-sm" />
                </FormItem>
              )}
            />

            <div className="tw-relative -tw-mx-4 !tw-mb-8">
              <div className="tw-h-[8px] tw-bg-[#F1F5F9] tw-w-screen tw-absolute tw-left-0" />
              <div className="tw-h-[8px] tw-relative"></div>
            </div>

            {/* Community Title */}
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="tw-flex tw-flex-col">
                  <FormLabel className="tw-text-left tw-text-base tw-font-medium">
                    Community Title<span className="tw-text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input {...field} className="tw-h-12 !tw-rounded-[34px]" />
                  </FormControl>
                  <FormMessage className="tw-text-red-500 tw-text-sm" />
                </FormItem>
              )}
            />

            {/* Username */}
            <FormField
              control={form.control}
              name="handle"
              render={({ field: formField }) => {
                const fieldError = form.formState.errors.handle?.message;
                return (
                  <FormItem className="tw-flex tw-flex-col">
                    <FormLabel className="tw-text-left tw-text-base tw-font-medium">
                      Username<span className="tw-text-red-500">*</span>
                    </FormLabel>
                    <FormControl>
                      <SignUpUsernameField
                        value={formField.value as string}
                        onChange={(newValue) => {
                          formField.onChange(newValue);
                        }}
                        onCheckingChange={setIsCheckingUsername}
                        className="tw-h-12 !tw-rounded-[34px]"
                        error={!!fieldError || usernameError !== null}
                        customStore={manageCommunityStore}
                        skipInitialValidation={true}
                      />
                    </FormControl>
                    <FormMessage className="tw-text-red-500 tw-text-sm">
                      {fieldError || usernameError}
                    </FormMessage>
                  </FormItem>
                );
              }}
            />

            <div className="tw-relative -tw-mx-4 !tw-mb-8">
              <div className="tw-h-[8px] tw-bg-[#F1F5F9] tw-w-screen tw-absolute tw-left-0" />
              <div className="tw-h-[8px] tw-relative"></div>
            </div>

            {/* Bio */}
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="tw-flex tw-flex-col">
                  <FormLabel className="tw-text-left tw-block tw-text-grey">
                    Add Community Bio
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      className="tw-resize-none"
                      placeholder="Community description"
                    />
                  </FormControl>
                  <FormMessage className="tw-text-red-500 tw-text-sm" />
                </FormItem>
              )}
            />

            <div className="tw-relative -tw-mx-4 !tw-mb-8">
              <div className="tw-h-[8px] tw-bg-[#F1F5F9] tw-w-screen tw-absolute tw-left-0" />
              <div className="tw-h-[8px] tw-relative"></div>
            </div>

            {/* Social Links */}
            <div className="tw-space-y-4">
              <h2 className="tw-text-left tw-text-base tw-font-medium">
                Social Links
              </h2>
              {(
                Object.keys(
                  form.getValues().socialMedia || {}
                ) as Array<SocialMediaKeys>
              ).map((platform) => (
                <FormField
                  key={platform}
                  control={form.control}
                  name={`socialMedia.${platform}` as const}
                  render={({ field }) => (
                    <SocialMediaInput platform={platform} field={field} />
                  )}
                />
              ))}
            </div>
          </div>

          {/* Save Button */}
          <div className="tw-p-4 tw-mt-auto">
            <Button
              type="submit"
              variant="default"
              className="tw-w-full tw-h-12 !tw-rounded-[38px] !tw-px-4 !tw-py-3"
              disabled={
                isSaving || isCheckingUsername || usernameError !== null
              }
            >
              {isSaving ? <LoadingSpinner /> : "Save Changes"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
});
