import { observer } from "mobx-react-lite";
import { Button } from "../shadcn-components/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn-components/select";
import { CirclePlus } from "lucide-react";
import { FC } from "react";

interface SearchInputWithLayoutProps {
  onCreateEventClick: () => void;
}
const SearchInputWithLayout : FC<SearchInputWithLayoutProps> = observer(() => {
  return (
    <div className="tw-hidden md:tw-flex tw-items-center tw-mx-4 tw-mb-6 tw-mt-4">
      <h1 className="tw-text-2xl tw-font-semibold tw-mr-auto tw-pl-4">Events</h1>
      <div className="tw-flex tw-items-center tw-gap-3 tw-whitespace-nowrap">
        <Button
          variant="default"
          className="tw-text-base tw-rounded-full tw-text-white hover:tw-bg-blue-700"
        >
          <CirclePlus className="tw-h-4 tw-w-4 tw-mr-2" />
          Create event
        </Button>
        <span className="tw-text-gray-600 tw-whitespace-nowrap">
          Popular in
        </span>
        <Select defaultValue="Delhi NCR">
          <SelectTrigger className="tw-w-[120px] ">
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="tw-border-custom-border">
            <SelectItem value="Delhi NCR">Delhi NCR</SelectItem>
            <SelectItem value="Mumbai">Mumbai</SelectItem>
            <SelectItem value="Bangalore">Bangalore</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
});

export default SearchInputWithLayout;
