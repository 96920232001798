import React from "react";
import { ImageUploadContainer } from "./image-upload-container";

export interface CommunityImageBannerProps {
  onCoverPhotoChange: (file: File, previewUrl: string) => void;
  onProfilePhotoChange: (file: File, previewUrl: string) => void;
  coverPhotoUrl?: string | null;
  profilePhotoUrl?: string | null;
}

export const CommunityImageBanner: React.FC<CommunityImageBannerProps> = ({
  onCoverPhotoChange,
  onProfilePhotoChange,
  coverPhotoUrl,
  profilePhotoUrl,
}) => {
  return (
    <div className="tw-relative tw-mb-16">
      {/* Cover Photo Section */}
      <div className="tw-relative tw-aspect-[2/1] tw-rounded-lg tw-bg-gradient-to-b tw-from-[#EBF4F5] tw-to-[#B5C6E0]">
        <ImageUploadContainer
          onImageChange={onCoverPhotoChange}
          aspectRatio={2 / 1}
          previewUrl={coverPhotoUrl}
          className="tw-h-full"
          buttonText="Add Cover Photo"
        />
      </div>

      {/* Profile Photo Section */}
      <div className="tw-absolute tw-left-4 tw-top-3/4">
        <div className="!tw-w-[88px] !tw-h-[88px]">
          <ImageUploadContainer
            onImageChange={onProfilePhotoChange}
            aspectRatio={1}
            previewUrl={profilePhotoUrl}
            cropShape="round"
            className="tw-h-full"
          />
        </div>
      </div>
    </div>
  );
};
