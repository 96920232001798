import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Button } from "../../shadcn-components/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../shadcn-components/tabs";
import { EventData } from "../../components/events/stores/event-store";
import { ChevronLeft } from "lucide-react";
import { exploreStore } from "../../stores/explore-store";
import {
  Community,
  communityStore,
  FollowerUser,
} from "../../stores/community-store";
import { Card, CardContent, CardHeader } from "../../shadcn-components/card";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import {
  getCommunityBanner,
  getCommunityLogo,
  getEventImage,
} from "../../utils/image-utils";
import { Badge } from "../../shadcn-components/badge";
import { AvatarCircles } from "../../components/communities/avatar-circles-with-followers";
import { getUsersPictures } from "../../utils/community-utils";

const EventCard: FC<EventData> = (event) => {
  const navigate = useNavigate();
  return (
    <div
      className="tw-rounded-xl tw-overflow-hidden tw-bg-white"
      onClick={() => navigate(`/e/${event.eventId}`)}
    >
      <div className="tw-aspect-[1.91/1] tw-relative tw-mb-2">
        <img
          src={event.logo ? getEventImage(event.logo) : ""}
          alt={event.name}
          className="tw-w-full tw-h-full tw-object-cover tw-rounded-2xl"
        />
      </div>
      <div>
        <div className="tw-flex tw-items-center tw-gap-1 tw-mb-1">
          <img
            src={getCommunityLogo(event.communityDetails.profileImageUrl)}
            alt="Community"
            className="tw-w-6 tw-h-6 tw-rounded-full"
          />
          <span className="tw-text-xs tw-text-gray-600 tw-truncate tw-overflow-hidden tw-whitespace-nowrap tw-max-w-[calc(100%-2rem)]">
            By {event.communityDetails.title}
          </span>
        </div>
        <h3 className="tw-text-base tw-font-semibold tw-mb-3 tw-text-left">
          {event.name}
        </h3>
        <div className="tw-flex tw-flex-col tw-gap-1 tw-text-gray-600">
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-sm">🕐</span>
            <span className="tw-text-sm">
              {new Date(event.startDateTime).toLocaleString("en-US", {
                weekday: "long",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-sm tw-ml-1">📍</span>
            <span className="tw-text-sm">{event.location.primaryAddress}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CommunityCard: FC<{
  community: Community;
  followersByCommunityId: Map<string, FollowerUser[]>;
}> = observer(({ community, followersByCommunityId }) => {
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(`/${community.handle}`);
  };

  return (
    <Card
      className="tw-overflow-hidden tw-w-full tw-mb-4 tw-border-0 tw-rounded-none tw-bg-none tw-shadow-none"
      onClick={onCardClick}
    >
      <CardHeader className="!tw-p-0">
        <div className="tw-h-[171px] tw-relative">
          <img
            src={getCommunityBanner(community.bannerImageUrls)}
            alt={community.title}
            className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
          />
        </div>
      </CardHeader>
      <CardContent className="tw-flex tw-flex-col !tw-pt-2 tw-px-0 tw-pb-0">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h3 className="tw-text-base tw-font-bold">{community.title}</h3>
          <div className="tw-flex tw-items-center tw-gap-1">
            <AvatarCircles
              pictures={getUsersPictures(community.id, followersByCommunityId)}
              followerCount={community.followerCount}
              onFollowersClick={() => {}}
              showFollowersText={false}
            />
          </div>
        </div>
        <p className="tw-text-sm tw-text-gray-600 tw-mt-2 tw-text-left tw-line-clamp-2">
          {community.description}
        </p>
        <span className="tw-text-sm tw-font-semibold tw-text-left tw-mt-2">
          @{community.handle}
        </span>
      </CardContent>
    </Card>
  );
});

export const ExploreContent: FC = observer(() => {
  const {
    exploreEvents,
    getEvents,
    exploreCommunities,
    getCommunities,
    isLoadingExploreCommunities,
    isLoadingExploreEvents,
    hasMoreCommunities,
    resetCommunities,
  } = exploreStore;
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (
        target.isIntersecting &&
        !isLoadingExploreCommunities &&
        hasMoreCommunities
      ) {
        getCommunities(false);
      }
    },
    [getCommunities, isLoadingExploreCommunities, hasMoreCommunities]
  );
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    const fetch = async () => {
      await getEvents();
      resetCommunities();
      await getCommunities(true);
    };
    fetch();
  }, [getCommunities, getEvents, resetCommunities]);
  useEffect(() => {
    const fetch = async () => {
      await communityStore.getCommunityFollowers(
        exploreCommunities.map((c) => c.id)
      );
    };
    fetch();
  }, [exploreCommunities.length]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
      window.scrollTo(0, 0);
    }
  }, [exploreStore.selectedTabValue]);

  const handleTabChange = (value: string) => {
    exploreStore.selectedTabValue = value;
    if (value === "communities") {
      resetCommunities();
      getCommunities(true);
    }
  };
  const tabTriggerStyles =
    "tw-px-4 tw-py-2 data-[state=active]:tw-bg-transparent data-[state=active]:tw-shadow-none tw-relative tw-h-auto \
  data-[state=active]:tw-text-[#1E28FF] tw-text-gray-500 tw-rounded-none tw-flex-1 tw-max-w-[200px] \
  before:tw-content-[''] before:tw-absolute before:tw-bottom-[-1px] before:tw-left-0 before:tw-right-0 \
  before:tw-h-[1px] before:tw-bg-gray-200 \
  after:tw-content-[''] after:tw-absolute after:tw-bottom-[-1px] after:tw-left-0 after:tw-right-0 \
  after:tw-h-[2px] after:tw-bg-[#1E28FF] after:tw-transform after:tw-scale-x-0 \
  data-[state=active]:after:tw-scale-x-100 after:tw-transition-transform \
  data-[state=active]:before:tw-opacity-0";

  return (
    <div className="tw-max-w-2xl tw-mx-auto tw-px-4" ref={containerRef}>
      <Tabs
        defaultValue="events"
        className="tw-w-full"
        onValueChange={handleTabChange}
        value={exploreStore.selectedTabValue}
      >
        <TabsList className="tw-h-auto tw-p-0 tw-bg-transparent tw-border-gray-200 tw-w-full tw-justify-start">
          <TabsTrigger value="events" className={tabTriggerStyles}>
            Events
          </TabsTrigger>
          <TabsTrigger value="communities" className={tabTriggerStyles}>
            Communities
          </TabsTrigger>
        </TabsList>

        <TabsContent value="events">
          <div className="tw-space-y-2">
            {isLoadingExploreEvents ? (
              <LoadingSpinner />
            ) : (
              exploreEvents.map((event) => (
                <EventCard key={event.eventId} {...event} />
              ))
            )}
          </div>
        </TabsContent>

        <TabsContent value="communities">
          <div>
            <div className="tw-grid tw-grid-cols-1 tw-gap-2">
              {exploreCommunities.map((c) => (
                <CommunityCard
                  key={c.id}
                  community={c}
                  followersByCommunityId={communityStore.followersByCommunityId}
                />
              ))}
            </div>

            <div ref={loadingRef} className="tw-py-4 tw-text-center">
              {isLoadingExploreCommunities && <LoadingSpinner />}
              {!hasMoreCommunities && exploreCommunities.length > 0 && (
                <div className="tw-h-2 tw-bg-gray-100 tw-w-full tw-mb-6" />
              )}
            </div>

            {!isLoadingExploreCommunities && !hasMoreCommunities && (
              <div className="tw-text-center tw-py-8">
                <p className="tw-text-base tw-mb-2">
                  Didn't find any communities?
                </p>
                <a
                  href="#"
                  className="tw-text-[#1E28FF] tw-font-medium"
                  onClick={() => navigate("/c/new")}
                >
                  Create One
                </a>
              </div>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
});
