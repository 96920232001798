import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "../../shadcn-components/button";
import { Label } from "../../shadcn-components/label";
import { Input } from "../../shadcn-components/input";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { Gender } from "../../stores/auth.types";
import { toast } from "../../hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "../../shadcn-components/phone";
import { OTPVerificationSheet } from "./otp-verification-sheet";
import signUpStore from "../../stores/signup-store";
import profileSettingsStore from "../../stores/profile-settings-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../shadcn-components/sheet";

const personalDetailsSchema = z.object({
  accountName: z.string().min(1, "Name is required"),
  handle: z.string().min(1, "Username is required"),
  phoneNumber: z.string().min(10, "Phone number must be at least 10 digits"),
  email: z.string().email("Invalid email format"),
  gender: z.nativeEnum(Gender),
});

type FormValues = z.infer<typeof personalDetailsSchema>;

const GENDER_OPTIONS = [
  { value: Gender.Woman, label: "Woman" },
  { value: Gender.Man, label: "Man" },
  { value: Gender.Others, label: "Others" },
];

export const PersonalDetailsForm: FC = observer(() => {
  const navigate = useNavigate();
  const [isOtpSheetOpen, setIsOtpSheetOpen] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const { editProfileData, getEditProfileData, updateProfile, isLoading } =
    profileSettingsStore;
  const [initialPhoneNumber, setInitialPhoneNumber] = useState("");

  useEffect(() => {
    const fetch = async () => {
      await getEditProfileData();
    };
    fetch();
  }, []);

  useEffect(() => {
    if (editProfileData?.phoneNumber) {
      setInitialPhoneNumber(editProfileData.phoneNumber);
    }
  }, [editProfileData]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(personalDetailsSchema),
    defaultValues: {
      gender: editProfileData?.gender || Gender.Woman,
      phoneNumber: editProfileData?.phoneNumber || "",
      accountName: editProfileData?.accountName || "",
      handle: editProfileData?.handle || "",
      email: editProfileData?.email || "",
    },
  });

  useEffect(() => {
    setValue("gender", editProfileData?.gender || Gender.Woman);
    setValue("phoneNumber", editProfileData?.phoneNumber || "");
    setValue("accountName", editProfileData?.accountName || "");
    setValue("handle", editProfileData?.handle || "");
    setValue("email", editProfileData?.email || "");
  }, [editProfileData, setValue]);

  const selectedGender = watch("gender");
  const phoneNumber = watch("phoneNumber");

  const handleVerifyOTP = async (otp: string) => {
    setIsVerifying(true);
    try {
      const res = await signUpStore.submitOTP(otp);
      setIsOtpSheetOpen(false);
      if (res) {
        const formData = {
          accountName: watch("accountName"),
          handle: watch("handle"),
          phoneNumber: watch("phoneNumber"),
          email: watch("email"),
          gender: watch("gender"),
        };
        await updateProfile(formData);
        navigate("/me");
      }
    } catch (error) {
      toast({
        title: "Verification Failed",
        description: "Failed to verify OTP. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendOTP = async () => {
    toast({
      title: "OTP Resent",
      description: "A new OTP has been sent to your phone number.",
    });
  };

  const onSubmit = async (data: FormValues) => {
    // Only show OTP verification if phone number was changed and is different from initial
    if (
      data.phoneNumber !== initialPhoneNumber &&
      data.phoneNumber.length >= 10
    ) {
      try {
        await signUpStore.getOtp(data.phoneNumber);
        setIsOtpSheetOpen(true);
      } catch (error) {
        toast({
          title: "Failed to send OTP",
          description: "There was an error sending the verification code.",
          variant: "destructive",
        });
      }
      return;
    }

    // If phone number wasn't changed or is the same as initial, proceed with profile update
    try {
      await updateProfile(data);
      navigate("/me");
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update profile. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-white">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="tw-p-4 tw-space-y-6">
          <div className="tw-space-y-2">
            <Label className="tw-block !tw-text-left tw-text-gray-700">
              Name <span className="tw-text-red-500">*</span>
            </Label>
            <Input
              {...register("accountName")}
              className="tw-rounded-full tw-border-gray-200"
              placeholder="Sam Alex"
            />
            {errors.accountName && (
              <p className="tw-text-red-500 tw-text-sm">
                {errors.accountName.message}
              </p>
            )}
          </div>

          <div className="tw-space-y-2">
            <Label className="tw-block !tw-text-left tw-text-gray-700">
              Username <span className="tw-text-red-500">*</span>
            </Label>
            <Input
              {...register("handle")}
              className="tw-rounded-full tw-border-gray-200"
              placeholder="sam_Alex"
            />
            {errors.handle && (
              <p className="tw-text-red-500 tw-text-sm">
                {errors.handle.message}
              </p>
            )}
          </div>

          <div className="tw-space-y-2">
            <Label className="tw-block !tw-text-left tw-text-gray-700">
              Whatsapp Number <span className="tw-text-red-500">*</span>
            </Label>
            <div className="tw-flex tw-items-center tw-gap-2">
              <PhoneInput
                {...register("phoneNumber")}
                value={editProfileData?.phoneNumber || ""}
                onChange={(e) => setValue("phoneNumber", e.target.value)}
                size={30}
              />
            </div>
            {errors.phoneNumber && (
              <p className="tw-text-red-500 tw-text-sm">
                {errors.phoneNumber.message}
              </p>
            )}
            <p className="tw-text-gray-500 tw-text-xs">
              We only use phone numbers to make sure everyone is real
            </p>
          </div>

          <div className="tw-space-y-2">
            <Label className="tw-text-gray-700 tw-block !tw-text-left">
              Email ID
            </Label>
            <Input
              {...register("email")}
              type="email"
              className="tw-rounded-full tw-border-gray-200"
              placeholder="samalex@gmail.com"
            />
            {errors.email && (
              <p className="tw-text-red-500 tw-text-sm">
                {errors.email.message}
              </p>
            )}
          </div>

          <div className="tw-space-y-2">
            <Label className="tw-text-gray-700 tw-block !tw-text-left">
              Gender <span className="tw-text-red-500">*</span>
            </Label>
            <div className="tw-flex tw-gap-3">
              {GENDER_OPTIONS.map(({ value, label }) => (
                <Button
                  key={value}
                  type="button"
                  variant={selectedGender === value ? "default" : "outline"}
                  className={`
                  tw-flex-1 tw-rounded-full
                  ${
                    selectedGender === value
                      ? "tw-bg-blue-600 tw-text-white hover:tw-bg-blue-700"
                      : "tw-border-gray-200 tw-text-gray-700"
                  }
                `}
                  onClick={() => setValue("gender", value)}
                >
                  {label}
                </Button>
              ))}
            </div>
            {errors.gender && (
              <p className="tw-text-red-500 tw-text-sm">
                {errors.gender.message}
              </p>
            )}
          </div>

          <Button
            type="submit"
            className="tw-w-full tw-rounded-full tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-mt-8"
          >
            Update Personal Details
          </Button>
        </form>
      )}

      <OTPVerificationSheet
        isOpen={isOtpSheetOpen}
        onClose={() => setIsOtpSheetOpen(false)}
        phoneNumber={phoneNumber}
        onVerify={handleVerifyOTP}
        isVerifying={isVerifying}
        onResendOTP={handleResendOTP}
      />
    </div>
  );
});

interface PersonalDetailsSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const PersonalDetailsSheet: FC<PersonalDetailsSheetProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side="bottom"
        className="tw-h-[100dvh] !tw-p-4 tw-max-h-[100dvh] tw-flex tw-flex-col tw-bg-white !tw-scrollbar-hide"
      >
        <SheetHeader className="tw-px-4 tw-py-3 tw-border-b tw-border-gray-100">
          <div className="tw-flex tw-items-center tw-gap-4">
            <SheetTitle className="tw-text-lg tw-font-medium">
              Personal Details
            </SheetTitle>
          </div>
        </SheetHeader>
        <div className="tw-flex-1 tw-overflow-y-auto">
          <PersonalDetailsForm />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PersonalDetailsSheet;
