import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Input } from "../../shadcn-components/input";
import { Textarea } from "../../shadcn-components/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { Button } from "../../shadcn-components/button";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  communityStore,
  formSchema,
  FormValues,
} from "../../stores/community-store";
import { useNavigate } from "react-router-dom";
import { SignUpUsernameField } from "../signup-username";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { Check, Image as ImageIcon, X } from "lucide-react";
import Cropper from "react-easy-crop";
import { InterestSelector } from "./category-selector";
import { SocialMediaInput } from "./social-media-fields";
interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface CroppedAreaPixels extends Area {}
interface CroppedArea extends Area {}

const CreateCommunityForm: React.FC = observer(() => {
  const { createCommunity, isSubmitting } = communityStore;
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);

  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
  const [profileCrop, setProfileCrop] = useState({ x: -1, y: -1 });
  const [coverZoom, setCoverZoom] = useState(1);
  const [profileZoom, setProfileZoom] = useState(1);
  const [coverCompletedCrop, setCoverCompletedCrop] = useState<{
    croppedArea: CroppedArea;
    croppedAreaPixels: CroppedAreaPixels;
  } | null>(null);
  const [profileCompletedCrop, setProfileCompletedCrop] = useState<{
    croppedArea: CroppedArea;
    croppedAreaPixels: CroppedAreaPixels;
  } | null>(null);

  const [coverPreview, setCoverPreview] = useState<string | null>(null);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);
  const [showCoverCropper, setShowCoverCropper] = useState(false);
  const [showProfileCropper, setShowProfileCropper] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      coverPhoto: undefined,
      profilePhoto: undefined,
      title: "",
      handle: "",
      description: "",
      location: "delhi_ncr",
      categories: [],
      socialMedia: {
        instagram: "",
        whatsapp: "",
        youtube: "",
        twitter: "",
        website: "",
      },
    },
    mode: "onSubmit",
  });
  const {
    formState: { errors },
  } = form;
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
    }
  }, [errors]);

  const handleCoverUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setCoverImage(reader.result as string);
          setCoverCrop({ x: 0, y: 0 });
          setCoverZoom(1);
          setShowCoverCropper(true);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const onCoverCropComplete = (
    croppedArea: CroppedArea,
    croppedAreaPixels: CroppedAreaPixels
  ) => {
    setCoverCompletedCrop({ croppedArea, croppedAreaPixels });
  };

  const handleCoverConfirm = async () => {
    if (!coverCompletedCrop || !coverImage) {
      return;
    }

    try {
      // Create canvas
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = coverImage;

      await new Promise((resolve) => {
        image.onload = () => {
          resolve(null);
        };
      });

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = coverCompletedCrop.croppedAreaPixels.width;
      canvas.height = coverCompletedCrop.croppedAreaPixels.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Failed to get canvas context");
        return;
      }

      ctx.drawImage(
        image,
        coverCompletedCrop.croppedAreaPixels.x * scaleX,
        coverCompletedCrop.croppedAreaPixels.y * scaleY,
        coverCompletedCrop.croppedAreaPixels.width * scaleX,
        coverCompletedCrop.croppedAreaPixels.height * scaleY,
        0,
        0,
        coverCompletedCrop.croppedAreaPixels.width,
        coverCompletedCrop.croppedAreaPixels.height
      );

      const blob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
      );

      const fileName = `cover-${Date.now()}.jpg`;
      const croppedFile = new File([blob], fileName, { type: "image/jpeg" });

      // Set form value and preview
      form.setValue("coverPhoto", croppedFile);
      const previewUrl = URL.createObjectURL(croppedFile);

      setCoverPreview(previewUrl);
      setCoverImage(null);
      setCoverCompletedCrop(null);
      setCoverCrop({ x: 0, y: 0 });
      setCoverZoom(1);
      setShowCoverCropper(false);
    } catch (e) {
      console.error("Error in cover confirmation:", e);
    }
  };

  const handleCoverCancel = () => {
    setCoverImage(null);
    setCoverPreview(null);
    setCoverCompletedCrop(null);
    setCoverCrop({ x: 0, y: 0 });
    setCoverZoom(1);
    form.setValue("coverPhoto", undefined);
    setShowCoverCropper(false);
  };

  const handleProfileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(reader.result as string);
          setProfileCrop({ x: 0, y: 0 });
          setProfileZoom(1);
          setShowProfileCropper(true);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const onProfileCropComplete = (
    croppedArea: CroppedArea,
    croppedAreaPixels: CroppedAreaPixels
  ) => {
    setProfileCompletedCrop({ croppedArea, croppedAreaPixels });
  };

  const handleProfileConfirm = async () => {
    if (!profileCompletedCrop || !profileImage) {
      return;
    }

    try {
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = profileImage;

      await new Promise((resolve) => {
        image.onload = () => {
          resolve(null);
        };
      });

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = profileCompletedCrop.croppedAreaPixels.width;
      canvas.height = profileCompletedCrop.croppedAreaPixels.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Failed to get canvas context");
        return;
      }

      ctx.drawImage(
        image,
        profileCompletedCrop.croppedAreaPixels.x * scaleX,
        profileCompletedCrop.croppedAreaPixels.y * scaleY,
        profileCompletedCrop.croppedAreaPixels.width * scaleX,
        profileCompletedCrop.croppedAreaPixels.height * scaleY,
        0,
        0,
        profileCompletedCrop.croppedAreaPixels.width,
        profileCompletedCrop.croppedAreaPixels.height
      );

      const blob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
      );

      const fileName = `profile-${Date.now()}.jpg`;
      const croppedFile = new File([blob], fileName, { type: "image/jpeg" });

      form.setValue("profilePhoto", croppedFile);
      const previewUrl = URL.createObjectURL(croppedFile);

      setProfilePreview(previewUrl);
      setProfileImage(null);
      setProfileCompletedCrop(null);
      setProfileCrop({ x: 0, y: 0 });
      setProfileZoom(1);
      setShowProfileCropper(false);
    } catch (e) {
      console.error("Error in profile confirmation:", e);
    }
  };

  const handleProfileCancel = () => {
    setProfileImage(null);
    setProfileCompletedCrop(null);
    setProfileCrop({ x: 0, y: 0 });
    setProfileZoom(1);
    setShowProfileCropper(false);
  };

  useEffect(() => {
    return () => {
      if (coverPreview) URL.revokeObjectURL(coverPreview);
      if (profilePreview) URL.revokeObjectURL(profilePreview);
    };
  }, []);

  const navigate = useNavigate();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const isCreated = await createCommunity(values);
    if (isCreated) {
      navigate(`/${form.getValues().handle}`);
    }
  };

  const labelClasses = "tw-text-left tw-text-base tw-font-medium";

  const separator = (
    <div className="tw-relative -tw-mx-4 !tw-mb-8">
      <div className="tw-h-[8px] tw-bg-[#F1F5F9] tw-w-screen tw-absolute tw-left-0" />
      <div className="tw-h-[8px] tw-relative"></div>
    </div>
  );

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-relative">
      {isSubmitting ? (
        <LoadingSpinner />
      ) : (
        <>
          {showCoverCropper && coverImage && (
            <div className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center">
              <div className="tw-relative tw-bg-gray-900 tw-w-full tw-max-w-md">
                <div className="tw-relative tw-aspect-[2/1]">
                  <Cropper
                    image={coverImage}
                    crop={coverCrop}
                    zoom={coverZoom}
                    aspect={2 / 1}
                    onCropChange={setCoverCrop}
                    onZoomChange={setCoverZoom}
                    onCropComplete={onCoverCropComplete}
                  />
                </div>
                <div className="tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-gap-2 tw-z-50">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleCoverCancel}
                    className="tw-bg-white !tw-border-[#EDEDEB]"
                    type="button"
                  >
                    <X className="tw-h-4 tw-w-4" />
                  </Button>
                  <Button
                    variant="default"
                    size="icon"
                    onClick={handleCoverConfirm}
                    className="tw-bg-indigo-500 hover:tw-bg-indigo-600 !tw-border-[#EDEDEB]"
                    type="button"
                  >
                    <Check className="tw-h-4 tw-w-4" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {showProfileCropper && profileImage && (
            <div className="tw-fixed tw-inset-0 tw-z-50 tw-bg-black/50 tw-flex tw-items-center tw-justify-center">
              <div className="tw-relative tw-bg-gray-900 tw-w-full tw-max-w-[300px]">
                <div className="tw-relative tw-aspect-square">
                  <Cropper
                    image={profileImage}
                    crop={profileCrop}
                    zoom={profileZoom}
                    aspect={1 / 1}
                    onCropChange={setProfileCrop}
                    onZoomChange={setProfileZoom}
                    onCropComplete={onProfileCropComplete}
                    classes={{ containerClassName: "!tw-m-0" }}
                  />
                </div>
                <div className="tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleProfileCancel}
                    className="tw-bg-white tw-h-8 tw-w-8 !tw-border-[#EDEDEB]"
                    type="button"
                  >
                    <X className="tw-h-4 tw-w-4" />
                  </Button>
                  <Button
                    variant="default"
                    size="icon"
                    onClick={handleProfileConfirm}
                    className="tw-bg-indigo-500 hover:tw-bg-indigo-600 tw-h-8 tw-w-8 !tw-border-[#EDEDEB]"
                    type="button"
                  >
                    <Check className="tw-h-4 tw-w-4" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="tw-flex tw-flex-col tw-flex-1 tw-p-4 "
            >
              <div className="tw-text-center tw-mb-2 tw-mt-4">
                <p className="tw-text-[#13131580] tw-font-medium tw-text-xs">
                  Choose a cover photo that represents your
                  <br />
                  community's vibe on the listing screen
                </p>
              </div>

              {/* Cover Photo */}
              <div className="tw-w-full tw-max-w-md tw-mx-auto">
                {/* Cover Photo Section */}
                <div className="tw-relative tw-mb-16">
                  <div className="tw-relative tw-aspect-[2/1] tw-rounded-lg tw-bg-gradient-to-b tw-from-[#EBF4F5] tw-to-[#B5C6E0]">
                    {coverPreview ? (
                      <>
                        <img
                          src={coverPreview}
                          alt="Cover"
                          className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
                        />
                        <Button
                          variant="ghost"
                          size="icon"
                          className="tw-absolute tw-bottom-4 tw-right-4 tw-bg-white/80 tw-z-50"
                          onClick={handleCoverUpload}
                          type="button"
                        >
                          <ImageIcon className="tw-h-4 tw-w-4" />
                        </Button>
                      </>
                    ) : (
                      <div className="tw-h-full tw-relative">
                        <Button
                          variant="outline"
                          className="tw-gap-2 tw-absolute tw-bottom-4 tw-right-4 tw-bg-white tw-border-none !tw-px-2 !tw-py-4"
                          onClick={handleCoverUpload}
                          type="button"
                        >
                          <ImageIcon className="tw-h-5 tw-w-5" />
                          Add Cover Photo
                        </Button>
                      </div>
                    )}
                  </div>

                  {/* Profile Photo Section */}
                  <div className="tw-absolute tw-left-4 tw-top-3/4">
                    <div className="tw-relative">
                      <div className="tw-relative tw-aspect-square !tw-w-[88px] !tw-h-[88px]">
                        {profilePreview ? (
                          <img
                            src={profilePreview}
                            alt="Profile"
                            className="tw-w-full tw-h-full tw-rounded-lg tw-object-cover"
                          />
                        ) : (
                          <div className="tw-w-full tw-h-full tw-bg-gray-200 tw-rounded-lg" />
                        )}
                        <Button
                          variant="ghost"
                          size="icon"
                          className="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-bg-white/80 tw-h-8 tw-w-8 !tw-p-1"
                          onClick={handleProfileUpload}
                          type="button"
                        >
                          <ImageIcon className="tw-h-6 tw-w-6" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Rest of the form fields */}
              <div className="tw-space-y-6">
                <FormField
                  control={form.control}
                  name="categories"
                  render={({ field }) => (
                    <FormItem className="tw-flex tw-flex-col">
                      <FormLabel className={labelClasses}>
                        Category
                        <span className="tw-text-red-500">*</span>
                      </FormLabel>
                      <InterestSelector
                        value={form.watch("categories")?.[0] || ""}
                        onChange={(value) => {
                          form.setValue("categories", [value]);
                        }}
                      />
                    </FormItem>
                  )}
                />

                {separator}
                {/* Title */}
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem className="tw-flex tw-flex-col">
                      <FormLabel className={labelClasses}>
                        Community Title
                        <span className="tw-text-red-500">*</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          className="tw-h-12 !tw-rounded-[34px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="handle"
                  render={({ field: formField }) => {
                    const fieldError = errors.handle?.message;
                    return (
                      <FormItem className="tw-flex tw-flex-col">
                        <FormLabel className={labelClasses}>
                          Username<span className="tw-text-red-500">*</span>
                        </FormLabel>
                        <FormControl>
                          <SignUpUsernameField
                            value={formField.value as string}
                            onChange={(newValue) => {
                              formField.onChange(newValue);
                            }}
                            onCheckingChange={setIsCheckingUsername}
                            className="tw-h-12 !tw-rounded-[34px]"
                            error={
                              !!fieldError ||
                              communityStore.usernameError !== null
                            }
                          />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
                {/* Bio */}
                {separator}
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="tw-text-left tw-block tw-text-grey">
                        Add Community Bio
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Community description"
                          className="tw-resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {separator}
                {/* Social Links */}
                <div className="tw-space-y-4">
                  <h2 className="tw-text-left tw-text-base tw-font-medium">
                    Social Links
                  </h2>
                  {(
                    Object.keys(form.getValues().socialMedia) as Array<
                      keyof FormValues["socialMedia"]
                    >
                  ).map((platform) => (
                    <FormField
                      key={platform}
                      control={form.control}
                      name={`socialMedia.${platform}`}
                      render={({ field }) => (
                        <SocialMediaInput platform={platform} field={field} />
                      )}
                    />
                  ))}
                </div>
              </div>
              {/* Submit Button */}
              <div className="tw-p-4 tw-mt-auto">
                <Button
                  type="submit"
                  variant={"default"}
                  className="tw-w-full tw-h-12 !tw-rounded-[38px] !tw-px-4 !tw-py-3"
                  disabled={isCheckingUsername || isSubmitting}
                >
                  Create
                </Button>
              </div>
            </form>
          </Form>
        </>
      )}
    </div>
  );
});

export default CreateCommunityForm;
