import { FC, useEffect, useState } from "react";
import { Avatar, AvatarFallback } from "../../../shadcn-components/avatar";
import { Button } from "../../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { Input } from "../../../shadcn-components/input";
import { manageEventStore } from "../stores/manage-event.store";
import { useParams } from "react-router-dom";
import { EventAttendee } from "../api/address/event-api";
import { Hourglass, UserCheck, UserX } from "lucide-react";

type StatusFilter = "pending" | "checkedIn" | "cancelled" | null;

export const GuestList: FC = observer(() => {
  const { checkInGuests, getEventGuests, isLoadingGuests, checkInGuest } =
    manageEventStore;
  const { eventId } = useParams<{ eventId: string }>();
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<StatusFilter>(null); // Default state is no filter

  const toggleFilter = (filter: StatusFilter) => {
    setStatusFilter((prev: StatusFilter) => (prev === filter ? null : filter));
  };
  

  useEffect(() => {
    const fetch = async () => {
      if (eventId) {
        await getEventGuests(eventId);
      }
    };
    fetch();
  }, [eventId]);

  const handleCheckIn = async (userId: string) => {
    if (eventId) {
      try {
        await checkInGuest(eventId, userId);
        await getEventGuests(eventId);
      } catch (error) {
        console.error("Failed to check in guest:", error);
      }
    }
  };

  if (isLoadingGuests) {
    return (
      <div className="tw-w-full tw-max-w-md tw-mx-auto tw-p-4">
        <div>Loading guests...</div>
      </div>
    );
  }

  const guests = Array.isArray(checkInGuests) ? checkInGuests : [];

  const filteredGuests = guests.filter((guest) => {
    const searchTerm = searchQuery.toLowerCase();
    const name = guest.userDetails.user.accountName.toLowerCase();
    const email = guest.userDetails.user.email?.toLowerCase();
    const matchesSearch =
      name.includes(searchTerm) || email?.includes(searchTerm);

    if (statusFilter === null) return matchesSearch; // No filter applied
    if (statusFilter === "pending")
      return (
        matchesSearch &&
        guest.status !== "checkedIn" &&
        guest.status !== "cancelled"
      );
    return matchesSearch && guest.status === statusFilter;
  });

  const StatusFilterButton = ({
    label,
    status,
    icon,
  }: {
    label: string;
    status: StatusFilter;
    icon: JSX.Element;
  }) => (
    <Button
      variant="outline"
      size="sm"
      onClick={() => toggleFilter(status)}
      className={`tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-border ${
        statusFilter === status
          ? "tw-bg-blue-50 tw-border-blue-500 tw-text-blue-500"
          : "tw-bg-white !tw-border-[#13131533]"
      } hover:tw-bg-blue-50 hover:tw-border-blue-500 hover:tw-text-blue-500`}
    >
      {icon}
      {label}
    </Button>
  );


  const StatusDisplay = ({ guest }: { guest: EventAttendee }) => {
    if (guest.status === "cancelled") {
      return (
        <div className="tw-min-w-[100px] tw-flex tw-items-center tw-justify-center">
          <span className="tw-text-red-500">Not Going</span>
        </div>
      );
    }

    return (
      <Button
        variant="default"
        size="sm"
        onClick={
          guest.status === "checkedIn"
            ? () => {}
            : () => handleCheckIn(guest.userId)
        }
        className={`tw-min-w-[100px] !tw-rounded-[38px] ${
          guest.status === "checkedIn"
            ? "!tw-bg-[#12B325] !hover:tw-bg-[#12B325]"
            : "tw-bg-[#0051FF] hover:tw-bg-[#0051FF]"
        } tw-text-white`}
      >
        {guest.status === "checkedIn" ? "Checked In" : "Check In"}
      </Button>
    );
  };

  return (
    <div className="tw-w-full tw-max-w-md tw-mx-auto tw-p-4">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-[30px]">
        <div className="tw-relative tw-w-full">
          <Input
            type="text"
            placeholder="Search event guests..."
            className="tw-w-full tw-p-2 tw-text-sm tw-border-custom-border"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="tw-flex tw-gap-2 tw-overflow-x-auto tw-overflow-hidden">
          <StatusFilterButton
            label="Pending"
            status="pending"
            icon={<Hourglass size={20} />}
          />
          <StatusFilterButton
            label="Checked In"
            status="checkedIn"
            icon={<UserCheck size={20} />}
          />
          <StatusFilterButton
            label="Not Going"
            status="cancelled"
            icon={<UserX size={20} />}
          />
        </div>
      </div>

      <div className="tw-space-y-4 tw-mt-4">
        {filteredGuests.map((guest) => (
          <div
            key={guest.userId}
            className="tw-flex tw-items-center tw-justify-between !tw-mb-6"
          >
            <div className="tw-flex tw-items-center tw-space-x-3">
              <Avatar className="tw-h-14 tw-w-14">
                <AvatarFallback className="tw-bg-gray-200">
                  {guest.userDetails.user.accountName
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <div className="tw-text-left">
                <div className="tw-font-medium tw-mb-1">
                  {guest.userDetails.user.accountName}
                </div>
                <div className="tw-text-sm tw-text-gray-500">
                  {guest.userDetails.user.email}
                </div>
              </div>
            </div>

            <StatusDisplay guest={guest} />
          </div>
        ))}
      </div>
    </div>
  );
});

export default GuestList;
