import { getEventImage } from "../../../utils/image-utils";
import { ImageUploadContainer } from "../../communities/image-upload-container";

interface EventBannerProps {
  bannerUrl: string | null | undefined;
  onBannerChange: (file: File, previewUrl: string) => void;
  isInitialLoad?: boolean;
}

export const EventBanner: React.FC<EventBannerProps> = ({
  bannerUrl,
  onBannerChange,
  isInitialLoad = false,
}) => {
  const finalUrl =
    isInitialLoad && bannerUrl ? getEventImage(bannerUrl) : bannerUrl;

  return (
    <div className="tw-w-full tw-max-w-md tw-mx-auto tw-mb-6">
      <ImageUploadContainer
        onImageChange={onBannerChange}
        aspectRatio={4/5}
        previewUrl={finalUrl}
        height={428}
        className="tw-h-full"
        buttonText="Change Event Banner"
      />
    </div>
  );
};
