"use client";

import * as React from "react";
import { cn } from "../lib/utils";

const spinnerVariants =
  "tw-w-16 tw-h-16 tw-border-4 tw-border-t-4 tw-border-gray-200 tw-border-t-gray-600 tw-rounded-full tw-animate-spin";

interface LoadingSpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const LoadingSpinner = React.forwardRef<HTMLDivElement, LoadingSpinnerProps>((props, ref) => {
  const { className, ...rest } = props;
  return <div ref={ref} className={cn(spinnerVariants, className)} {...rest} />;
});

LoadingSpinner.displayName = "LoadingSpinner";

export { LoadingSpinner };