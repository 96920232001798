import { observer } from "mobx-react-lite";
import instagramIcon from "../../static/instagram.svg";
import twitterIcon from "../../static/twitter.svg";
import youtubeIcon from "../../static/youtube.svg";
import whatsappIcon from "../../static/whatsapp-black.svg";
import { Globe } from "lucide-react";
import {
  FormItem,
  FormControl,
  FormMessage,
} from "../../shadcn-components/form";
import { Input } from "../../shadcn-components/input";
import { ChangeEvent } from "react";

interface SocialMediaInputProps {
  platform: string;
  field: any;
  initialValue?: string;
  onBlur?: (platform: string, value: string) => void;
  onChange?: (platform: string, value: string) => void;
  error?: string;
  disabled?: boolean;
  className?: string;
}

export const SocialMediaInput: React.FC<SocialMediaInputProps> = observer(
  ({ 
    platform, 
    field, 
    initialValue = "", 
    onBlur, 
    onChange,
    error,
    disabled = false,
    className = ""
  }) => {
    const getIcon = (platform: string) => {
      switch (platform) {
        case "instagram":
          return (
            <img
              src={instagramIcon}
              alt="Instagram"
              className="tw-w-5 tw-h-5"
            />
          );
        case "whatsapp":
          return (
            <img src={whatsappIcon} alt="WhatsApp" className="tw-w-5 tw-h-5" />
          );
        case "youtube":
          return (
            <img src={youtubeIcon} alt="YouTube" className="tw-w-5 tw-h-5" />
          );
        case "twitter":
          return (
            <img src={twitterIcon} alt="Twitter" className="tw-w-5 tw-h-5" />
          );
        case "website":
          return <Globe className="tw-w-5 tw-h-5" />;
        default:
          return null;
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(platform, e.target.value);
      }
      if (field.onBlur) {
        field.onBlur(e);
      }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(platform, e.target.value);
      }
      if (field.onChange) {
        field.onChange(e);
      }
    };

    return (
      <FormItem>
        <FormControl>
          <div className="tw-relative">
            <div className="tw-absolute tw-left-4 tw-top-1/2 -tw-translate-y-1/2">
              {getIcon(platform)}
            </div>
            <Input
              {...field}
              defaultValue={initialValue}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              className={`tw-h-12 !tw-rounded-[34px] tw-pl-12 ${className}`}
              placeholder={platform.charAt(0).toUpperCase() + platform.slice(1)}
            />
          </div>
        </FormControl>
        {error && <FormMessage>{error}</FormMessage>}
      </FormItem>
    );
  }
);