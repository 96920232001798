import { useLocation, useNavigate } from "react-router-dom";
import { Home, Search, Calendar, User } from "lucide-react";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import { getUserImage } from "../utils/image-utils";
import { authStore } from "../stores/auth-store";
import { Button } from "../shadcn-components/button";

export interface BottomNavProps {
  profilePicture?: string;
}

export const BottomNav: FC<BottomNavProps> = observer(({ profilePicture }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = authStore.isAuthenticated;

  const navItems = [
    { icon: Home, label: "Home", path: "/" },
    { icon: Search, label: "Explore", path: "/explore" },
    { icon: Calendar, label: "Calendar", path: "/calendar" },
    isAuthenticated
      ? {
          icon: User,
          label: "Me",
          path: "/me",
          isProfile: true,
        }
      : {
          path: "/join",
          isLogin: true,
        },
  ];

  const renderIcon = (item: {
    icon?: typeof Home;
    isProfile?: boolean;
    isLogin?: boolean;
  }) => {
    if (item.isProfile && profilePicture) {
      return (
        <div className="tw-w-6 tw-h-6 tw-rounded-full tw-overflow-hidden">
          <img
            src={getUserImage(profilePicture)}
            alt="Profile"
            className="tw-w-full tw-h-full tw-object-cover"
          />
        </div>
      );
    }

    if (item.isLogin) {
      return <Button size={"sm"}>Login</Button>;
    }
    if (item.icon) {
      const Icon = item.icon;
      return <Icon size={24} />;
    }

    return null;
  };

  return (
    <nav className="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-border-t tw-border-gray-200 tw-flex tw-justify-around tw-py-2 md:tw-hidden">
      {navItems.map((item) => {
        const isActive = location.pathname === item.path;
        return (
          <button
            key={item.label}
            onClick={() => navigate(item.path)}
            className={`tw-flex tw-flex-col tw-items-center tw-px-3 tw-py-1 ${
              isActive ? "tw-text-primary" : "tw-text-gray-600"
            }`}
          >
            {renderIcon(item)}
            <span className="tw-text-xs tw-mt-1">{item.label}</span>
          </button>
        );
      })}
    </nav>
  );
});
