import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn-components/card";
import { eventStore, Event } from "./events/stores/event-store";
import { CommunitiesHome } from "./communities/communities-home";

import bhagClubProfile from "../static/bhag-profile-pic.png";
import bhagClubBanner from "../static/bhag-banner.jpg";
import SearchInputWithLayout from "./explore-events-search";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineHeader,
  TimelineIcon,
  TimelineItem,
} from "../shadcn-components/timeline";
import { Calendar, Clock } from "lucide-react";
import MobileEventsExplore from "./explore-events-mobile";
import { useNavigate } from "react-router-dom";
import { InterestButtons } from "./interest-buttons";

interface HubProps {
  view: "events" | "communities";
}

export interface GroupedEvents {
  [date: string]: Event[];
}

export const ExploreHub: FC<HubProps> = observer(({ view }) => {
  const [interestsFilter, setInterestsFilter] = useState<string[]>([]);

  const groupEventsByDate = (events: Event[]): GroupedEvents => {
    return events.reduce((acc: GroupedEvents, event) => {
      const dateStr = event.date.toDateString();
      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      acc[dateStr].push(event);
      return acc;
    }, {});
  };

  const formatEventTime = (date: Date): string => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatEventDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}, ${year}`;
  };

  const getDaySuffix = (day: number): string => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const renderEventCard = (event: Event) => (
    <Card
      key={event.id}
      className="tw-overflow-hidden tw-border-custom-border hover:tw-cursor-pointer tw-mb-4 tw-w-full max-w-[280px]"
    >
      <div className="tw-relative tw-p-3 tw-pb-0">
        <img
          src={event.bannerImage}
          alt={event.title}
          className="tw-w-full tw-h-32 tw-object-cover"
        />
      </div>
      <CardHeader className="tw-py-3 tw-px-3">
        <CardTitle className="tw-text-xl tw-text-left tw-font-semibold tw-truncate">
          {event.title}
        </CardTitle>
      </CardHeader>
      <CardContent className="tw-space-y-1 tw-px-3 tw-pb-3">
        <div className="tw-flex tw-items-center tw-text-xs tw-text-gray-500 tw-pb-3">
          <Clock className="tw-w-3 tw-h-3 tw-mr-1" />
          <span className="tw-mr-2 tw-text-grey">{formatEventTime(event.date)}</span>
          <Calendar className="tw-w-3 tw-h-3 tw-mr-1" />
          <span className="tw-text-grey">{formatEventDate(event.date)}</span>
        </div>
        <p className="tw-text-xs tw-line-clamp-2 tw-text-left tw-text-grey">{event.description}</p>
      </CardContent>
      <CardFooter className="tw-justify-start tw-pb-2 tw-px-3">
        <div className="tw-flex tw-items-center">
          <img
            src={event.profilePicture}
            alt={event.organizer}
            className="tw-w-5 tw-h-5 tw-rounded-full tw-mr-1"
          />
          <span className="tw-text-xs tw-font-medium">{event.organizer}</span>
        </div>
      </CardFooter>
    </Card>
  );

  const navigate = useNavigate();
  const handleCreateEventClick = () => {
    navigate(`/e/create`);
  };

  return (
    <>
      {view === "events" && <SearchInputWithLayout onCreateEventClick={handleCreateEventClick} />}
      <div className="tw-p-4 tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
          <InterestButtons
            selectedInterestsIds={[]}
            onInterestsChange={() => {}}
            // maxSelectedCount={5}
            layoutType="horizontal"
          />
        </div>
      {view === "events" ? (
        <section className="tw-relative tw-pl-4">
          {/* Desktop view */}
          <div className="tw-hidden md:tw-block">
            <Timeline className="tw-ml-[-1rem]">
              {Object.entries(groupEventsByDate(eventStore.events)).map(
                ([dateStr, events], index) => (
                  <TimelineItem key={dateStr}>
                    <TimelineConnector className="tw-bg-grey" />
                    <TimelineHeader className="tw-gap-2">
                      <TimelineIcon className="tw-bg-grey" />
                      <h3 className="tw-text-lg tw-font-semibold tw-text-grey">
                        {dateStr}
                      </h3>
                    </TimelineHeader>
                    <TimelineContent className="tw-mt-2 tw-pl-6 tw-pb-0">
                      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-4">
                        {events.map(renderEventCard)}
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                )
              )}
            </Timeline>
          </div>
          {/* Mobile view */}
          <div className="md:tw-hidden">
            <MobileEventsExplore />
          </div>
        </section>
      ) : (
        <CommunitiesHome />
      )}
    </>
  );
});

export default ExploreHub;

const communitiesData = [
  {
    id: 1,
    name: "Bhag Club",
    handle: "@bhagclub",
    description:
      "Bhag Club is India's spiciest run club, with group runs every weekend across Delhi NCR.",
    followers: "2Mn",
    profileImage: bhagClubProfile,
    bannerImage: bhagClubBanner,
    verified: true,
  },
  {
    id: 2,
    name: "Delhi Royal Enfield Riders",
    handle: "@delhiriders",
    description:
      "Delhi Royal Enfield Riders is India's most exciting riding club, with group rides every weekend across Delhi.",
    followers: "200k",
    profileImage: bhagClubProfile,
    bannerImage: bhagClubBanner,
    verified: true,
  },
  // Add more community data as needed
];
