export interface User {
  userId: string;
  handle: string;
  profilePicture?: string;
  phoneNumber: string;
  accountName: string;
  email?: string;
  gender?: Gender;
  createdOn: string;
  bio?: string;
  igHandle?: string;
}

export enum Gender {
  Man = "Man",
  Woman = "Woman",
  Others = "Others",
}

export type ApiError = {
  message: string;
  code?: string;
  status?: number;
};

export interface AdminUser extends User {
  phoneNumber: string;
}
