import { FC, useState } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../shadcn-components/avatar";
import { Button } from "../../shadcn-components/button";
import signUpStore from "../../stores/signup-store";
import { Image as ImageIcon } from "lucide-react";
import { ImageCropper } from "../communities/image-uploader";

interface ProfilePictureUploadProps {
  size?: number;
  initialImage?: string;
  onImageChange: (imageKey: string | undefined) => void;
  placeholderText?: string;
}

export const ProfilePictureUpload: FC<ProfilePictureUploadProps> = ({
  size = 128,
  initialImage,
  onImageChange,
  placeholderText,
}) => {
  const [tempImage, setTempImage] = useState<string | null | undefined>(
    initialImage
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avatarKey, setAvatarKey] = useState(Date.now());

  const handleImageSelect = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result as string);
      setIsModalOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const handleCropConfirm = async (croppedFile: File) => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage); // Revoke the previous object URL
    }

    const croppedImageUrl = URL.createObjectURL(croppedFile); // Create a new object URL for the cropped image
    setTempImage(croppedImageUrl);

    try {
      const imageKey = await signUpStore.uploadImageWithPresignedUrl(
        croppedFile
      );
      if (imageKey) {
        onImageChange(imageKey);
        setAvatarKey(Date.now());
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (e) {
      console.error("Error in profile confirmation:", e);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleCropCancel = () => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage);
    }
    setTempImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <Avatar
        key={avatarKey}
        className="tw-relative tw-border tw-border-gray-200"
        style={{ width: size, height: size }}
      >
        {tempImage ? (
          <AvatarImage src={tempImage} alt="Profile Picture" />
        ) : (
          <AvatarFallback>{placeholderText}</AvatarFallback>
        )}
      </Avatar>
      <Button
        type="button"
        variant="outline"
        className="tw-mt-4 tw-text-[#1E28FF] !tw-border-[#1E28FF] !tw-rounded-[38px]"
        onClick={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/*";
          input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
              handleImageSelect(file);
            }
          };
          input.click();
        }}
      >
        <ImageIcon className="tw-w-4 tw-h-4 tw-mr-2" />
        {placeholderText ? "Edit Photo" : "Add Profile Photo"}
      </Button>
      {isModalOpen && tempImage && (
        <div className="tw-fixed tw-inset-0 tw-bg-gray-500/75 tw-z-50 tw-flex tw-items-center tw-justify-center">
          <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-xl tw-w-[90%] md:tw-w-[60%]">
            <ImageCropper
              image={tempImage}
              aspectRatio={1}
              onConfirm={handleCropConfirm}
              onCancel={handleCropCancel}
              containerClassName=""
              height={300}
            />
          </div>
        </div>
      )}
    </div>
  );
};
