import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../shadcn-components/button";
import {
  ArrowLeft,
  ArrowRightFromLine,
  Check,
  MoreVertical,
  PhoneOutgoing,
  Share2,
} from "lucide-react";
import { Avatar, AvatarImage } from "@radix-ui/react-avatar";
import {
  CommunityFollowAction,
  communityStore,
} from "../../stores/community-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import signUpStore from "../../stores/signup-store";
import { AvatarCircles } from "./avatar-circles-with-followers";
import SocialMediaSection from "./social-media-links";
import { EventCard } from "./community-events-section";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../shadcn-components/dropdown-menu";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../shadcn-components/alert-dialog";
import { AvatarFallback } from "../../shadcn-components/avatar";
import { cn } from "../../lib/utils";
import {
  getCommunityBanner,
  getCommunityLogo,
  getUserImage,
  ImageType,
} from "../../utils/image-utils";
import { getUsersPictures } from "../../utils/community-utils";
import whatsappIcon from "../../static/whatsapp.svg";

export const CommunityView: FC = observer(() => {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const {
    interestOptions,
    citiesOptions,
    getAllClientSideData: getAllOptionsForSignUp,
  } = signUpStore;
  const {
    setCurrentCommunityViewName,
    communityData,
    isProfileLoading,
    getCommunity,
    setProfileLoading,
    eventsData,
    getEventsOfCommunity,
    getAdmins,
    adminsList,
    getCommunityFollowers,
    followersByCommunityId,
  } = communityStore;
  useEffect(() => {
    const fetchCommunity = async () => {
      await getAllOptionsForSignUp();
      if (communityHandle) {
        await getCommunity(communityHandle);
      }
    };
    fetchCommunity();
  }, [communityHandle, getAllOptionsForSignUp, getCommunity]);

  useEffect(() => {
    const fetchEvents = async () => {
      if (communityData?.id && !isProfileLoading) {
        await getEventsOfCommunity(communityData.id);
        await getCommunityFollowers([communityData.id]);
        await getAdmins(communityData.id);
      }
    };
    fetchEvents();
  }, [communityData?.id, isProfileLoading, getEventsOfCommunity, getAdmins]);

  useEffect(() => {
    communityStore.setCurrentCommunityViewName(communityHandle);
  }, [communityHandle, setCurrentCommunityViewName]);

  const navigate = useNavigate();

  const followOrUnfollow = useCallback(async () => {
    if (!communityData?.id) {
      return;
    }
    setProfileLoading(true);
    try {
      await communityStore.followOrUnfollow(
        communityData.isLoggedInUserFollower
          ? CommunityFollowAction.unFollow
          : CommunityFollowAction.follow
      );
    } catch (error) {
      console.error("Error following/unfollowing community:", error);
    }
  }, [
    communityData?.id,
    communityData?.isLoggedInUserFollower,
    setProfileLoading,
  ]);

  const handleFollowClick = async () => {
    if (communityData?.isUserNotLoggedIn) {
      navigate("/join");
    }
    if (communityData?.isLoggedInUserAdmin) {
      navigate(`/${communityData?.handle}/manage`);
      return;
    }
    await followOrUnfollow();
    if (communityData?.id) {
      await getCommunity(communityData?.handle);
    }
  };

  const handleShareClick = () => {
    if (navigator.share && communityData) {
      navigator
        .share({
          title: communityData.title,
          text: `Check out the community ${communityData.title} on our platform!`,
          url: window.location.href,
        })
        .catch((error) => {
          console.error("Error sharing:", error);
          // Fallback: Copy to clipboard if native share fails
          navigator.clipboard
            .writeText(window.location.href)
            .then(() => alert("Link copied to clipboard"));
        });
    } else {
      // Fallback for browsers not supporting Web Share API
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => alert("Link copied to clipboard"));
    }
  };

  if (isProfileLoading) {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const category =
    interestOptions &&
    interestOptions?.find((i) => i.id === communityData?.categories?.[0])
      ?.display_en;
  return (
    <div className="tw-w-full tw-bg-white">
      {/* Header Container */}
      <div className="tw-relative">
        {/* Banner Image */}
        <div className="tw-w-full tw-h-48">
          {communityData?.bannerImageUrls ? (
            <img
              src={getCommunityBanner(communityData.bannerImageUrls)}
              alt={`${communityData?.title} banner`}
              className="tw-w-full tw-h-full tw-object-cover"
            />
          ) : (
            <div className="tw-w-full tw-h-full tw-bg-gray-100 tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-6xl tw-font-semibold tw-text-gray-400">
                {communityData?.title?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
        </div>
        {/* Floating Navigation Buttons */}
        <div className="tw-absolute tw-top-4 tw-left-0 tw-right-0 tw-px-4 tw-flex tw-justify-between tw-items-center">
          <button
            onClick={() => navigate(-1)}
            className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-full"
          >
            <ArrowLeft className="tw-w-6 tw-h-6" />
          </button>
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button
              className="tw-flex tw-items-center  tw-bg-white tw-gap-2"
              onClick={handleShareClick}
            >
              <Share2 className="tw-w-5 tw-h-5 tw-text-black" />
              <span className="tw-text-black tw-font-semibold tw-text-sm">
                Share
              </span>
            </Button>
            {communityData?.isLoggedInUserFollower && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-white !tw-text-black !tw-p-0">
                    <MoreVertical className="tw-w-5 tw-h-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  className="tw-w-48 tw-border-[#F1F5F9]"
                >
                  <DropdownMenuItem
                    onClick={() => setIsContactDialogOpen(true)}
                  >
                    <PhoneOutgoing />
                    Contact Admins
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    onClick={handleFollowClick}
                    className="tw-text-red-600"
                  >
                    <ArrowRightFromLine />
                    Unfollow
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}

            <AlertDialog
              open={isContactDialogOpen}
              onOpenChange={setIsContactDialogOpen}
            >
              <AlertDialogContent className="tw-max-w-[400px] tw-bg-white">
                <AlertDialogHeader>
                  <AlertDialogTitle className="tw-text-xl">
                    Contact
                  </AlertDialogTitle>
                  <AlertDialogCancel
                    className="tw-absolute tw-right-3 tw-top-3 !tw-p-1 hover:tw-bg-gray-100 !tw-border-none"
                    onClick={() => setIsContactDialogOpen(false)}
                  >
                    X
                  </AlertDialogCancel>
                </AlertDialogHeader>
                <div className="tw-p-4 tw-bg-gray-50 tw-rounded-lg">
                  {adminsList.map((admin) => (
                    <div
                      key={admin.userId}
                      className="tw-flex tw-items-center tw-justify-between tw-py-3 first:tw-pt-0 last:tw-pb-0"
                    >
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <Avatar className="tw-w-10 tw-h-10 tw-rounded-full">
                          <AvatarImage
                            src={getUserImage(admin.profilePicture)}
                            alt={admin.accountName}
                            className="tw-rounded-full"
                          />
                        </Avatar>
                        <span className="tw-font-medium">
                          {admin.accountName}
                        </span>
                      </div>
                      <Button className="!tw-bg-green-500 tw-text-white tw-rounded-full tw-py-2 tw-px-4">
                        <img
                          src={whatsappIcon}
                          className="tw-w-5 tw-h-5"
                          alt="WhatsApp"
                        />
                      </Button>
                    </div>
                  ))}
                </div>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>

        <div className="tw-px-4">
          {/* Logo and Join Button Row */}
          <div className="tw-relative -tw-mt-6 tw-flex tw-items-center tw-justify-between">
            <div className="tw-rounded-3xl tw-w-[88px] tw-h-[88px]">
              <Avatar className="tw-w-[88px] tw-h-[88px] tw-rounded-2xl">
                {communityData?.profileImageUrl && (
                  <AvatarImage
                    src={getCommunityLogo(
                      communityData.profileImageUrl,
                      ImageType.High
                    )}
                    alt={communityData?.title}
                    className="tw-rounded-2xl tw-border-4 tw-border-white"
                  />
                )}
                <AvatarFallback className="tw-bg-gray-200 tw-text-gray-700 !tw-w-[88px] !tw-h-[88px] tw-rounded-none">
                  {communityData?.title?.charAt(0)}
                </AvatarFallback>
              </Avatar>
            </div>
            <Button
              variant={
                communityData?.isLoggedInUserFollower ? "outline" : "default"
              }
              size="sm"
              className={cn(
                `!tw-rounded-[38px] !tw-p-4 tw-mt-8`,
                communityData?.isLoggedInUserFollower
                  ? "!tw-bg-[#13131533] !tw-border-none"
                  : ""
              )}
              onClick={handleFollowClick}
              disabled={communityData?.isLoggedInUserFollower}
            >
              {communityData?.isLoggedInUserFollower && (
                <Check className="tw-w-4 tw-h-4" />
              )}
              {communityData?.isLoggedInUserFollower
                ? "Joined"
                : communityData?.isLoggedInUserAdmin
                ? "Manage"
                : "Follow"}
            </Button>
          </div>

          {/* Handle and Category */}
          <div className="tw-mt-4 tw-flex tw-items-center tw-gap-2 tw-text-xs  tw-mb-2">
            <span>@{communityData?.handle}</span>
            {category && (
              <div>
                <span>•</span>
                <span>{category}</span>
              </div>
            )}
          </div>

          {/* Title */}
          <h1 className="tw-text-2xl tw-font-bold tw-mt-1 tw-text-left tw-mb-3">
            {communityData?.title}
          </h1>

          {/* Stats */}
          <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1 tw-text-sm tw-text-gray-600">
            {communityData && (
              <AvatarCircles
                pictures={getUsersPictures(
                  communityData.id,
                  followersByCommunityId
                )}
                followerCount={communityData?.followerCount}
                onFollowersClick={() => {}}
                showFollowersText={false}
              />
            )}
            {communityData?.location && (
              <div>
                <span>•</span>
                <span>📍</span>
                <span>
                  {
                    citiesOptions.find((c) => c.id === communityData?.location)
                      ?.display_en
                  }
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Description */}
      {(communityData?.description || communityData?.socialMedia) && (
        <div className="tw-p-4">
          <p className="tw-text-base tw-text-grey tw-text-left">
            {communityData?.description}
          </p>
          {communityData?.socialMedia && (
            <SocialMediaSection socialMedia={communityData.socialMedia} />
          )}
        </div>
      )}
      <div className="tw-p-4 tw-bg-[#F1F5F9]">
        <div className="tw-grid tw-grid-cols-2 tw-gap-3">
          {Array.isArray(eventsData) && eventsData.length > 0 ? (
            eventsData.map((event) => (
              <EventCard
                key={event.eventId}
                eventData={event}
                communityLogo={communityData?.profileImageUrl}
                communityTitle={communityData?.title}
              />
            ))
          ) : (
            <div className="tw-col-span-2 tw-text-center tw-text-gray-500 tw-py-8">
              No upcoming events
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
