import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { Input } from "../../shadcn-components/input";
import { Button } from "../../shadcn-components/button";
import { Label } from "../../shadcn-components/label";
import { useForm } from "react-hook-form";
import signUpStore, {
  formSchemaNew,
  FormValuesUpdated,
} from "../../stores/signup-store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../stores/auth-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { ProfilePictureUpload } from "./profile-picture-upload";

export const ProfileForm: FC = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm<FormValuesUpdated>({
    resolver: zodResolver(formSchemaNew),
    defaultValues: {
      accountName: "",
      email: "",
      profilePicture: undefined,
      gender: "",
    },
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { isSubmittingProfile, uploadedImageKey } = signUpStore;
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(
    uploadedImageKey || undefined
  );
  const selectedGender = watch("gender");

  const handleImageChange = (imageKey: string | undefined) => {
    setUploadedImage(imageKey);
    setValue("profilePicture", imageKey);
  };

  const onSubmit = async (data: FormValuesUpdated) => {
    const isValid = await trigger();
    if (!isValid) return;
    // we are already submitting profile picture in this submit profile method.
    const success = await signUpStore.submitProfile(data);

    if (success) {
      await authStore.refreshAuth();
      navigate("/explore");
    }
  };

  const shouldShowError = (fieldName: keyof FormValuesUpdated) => {
    return (touchedFields[fieldName] || isSubmitted) && errors[fieldName];
  };
  const navigate = useNavigate();

  return (
    <div className="tw-min-h-screen tw-p-4">
      {isSubmittingProfile ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-flex-col tw-items-center tw-mb-6">
            <ProfilePictureUpload
              size={128}
              initialImage={uploadedImage}
              onImageChange={handleImageChange}
              placeholderText={watch("accountName")?.[0]?.toUpperCase()}
            />
          </div>

          {/* Form Fields */}
          <div className="tw-space-y-6">
            <div className="tw-space-y-2">
              <Label htmlFor="name" className="tw-block tw-text-left">
                Name <span className="tw-text-red-500">*</span>
              </Label>
              <Input
                id="name"
                className="tw-w-full !tw-rounded-[38px]"
                {...register("accountName", {
                  onChange: () => {
                    if (touchedFields.accountName) {
                      trigger("accountName");
                    }
                  },
                })}
                aria-invalid={shouldShowError("accountName") ? "true" : "false"}
              />
              {shouldShowError("accountName") && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.accountName?.message}
                </p>
              )}
            </div>

            <div className="tw-space-y-2">
              <Label htmlFor="email" className="tw-block tw-text-left">
                Email<span className="tw-text-red-500">*</span>
              </Label>
              <Input
                id="email"
                type="email"
                className="tw-w-full !tw-rounded-[38px]"
                {...register("email")}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {touchedFields.email && errors.email && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="tw-space-y-2">
              <Label className="tw-block tw-text-left">
                Gender <span className="tw-text-red-500">*</span>
              </Label>
              <div className="tw-flex tw-gap-2">
                {["Woman", "Man", "Others"].map((gender) => (
                  <Button
                    key={gender}
                    type="button"
                    variant="outline"
                    className={`
                    tw-flex-1 tw-h-10 !tw-rounded-[38px] tw-border tw-transition-all
                    ${
                      selectedGender === gender
                        ? "!tw-border-[#1E28FF] tw-bg-[#1E28FF]/10 !tw-text-[#1E28FF]"
                        : "tw-border-[#131315]/10 tw-text-gray-600 tw-hover:tw-bg-gray-50"
                    }
                  `}
                    onClick={() =>
                      setValue("gender", gender, { shouldValidate: false })
                    }
                  >
                    {gender}
                  </Button>
                ))}
              </div>
              {touchedFields.gender && errors.gender && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.gender.message}
                </p>
              )}
            </div>
          </div>

          {signUpStore.profileSubmitError && (
            <p className="tw-text-red-500 tw-text-sm tw-mt-4">
              {signUpStore.profileSubmitError}
            </p>
          )}

          <Button
            type="submit"
            className="tw-w-full tw-mt-8"
            disabled={signUpStore.isSubmittingProfile}
          >
            {signUpStore.isSubmittingProfile ? "Submitting..." : "Submit"}
          </Button>
        </form>
      )}
    </div>
  );
});
