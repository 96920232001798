import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface MapProps {
  latitude: number;
  longitude: number;
}

export const MapView: FC<MapProps> = observer(({ latitude, longitude }) => {
  const position = { lat: latitude, lng: longitude };
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  if (!apiKey) {
    throw new Error("GOOGLE_MAPS_API_KEY is not defined in .env file");
  }

  return (
    <APIProvider apiKey={apiKey}>
      <Map
        center={position} // Changed from defaultCenter to center
        zoom={14}
        mapId="DEMO_MAP_ID"
      >
        <AdvancedMarker position={position} />
      </Map>
    </APIProvider>
  );
});
