import { FC } from "react";
import { Button } from "../shadcn-components/button";
import { allInterests } from "../utils/interests-utils";
import signUpStore, { OptionItem } from "../stores/signup-store";
import { observer } from "mobx-react-lite";

interface InterestsProps {
  selectedInterestsIds: string[];
  onInterestsChange: (interests: string[]) => void;
  layoutType?: "grid" | "horizontal";
  maxSelectedCount?: number;
}

export const InterestButtons: FC<InterestsProps> = observer(
  ({
    selectedInterestsIds: selectedInterests,
    onInterestsChange,
    layoutType = "grid",
    maxSelectedCount = 5,
  }) => {
    const { interestOptions } = signUpStore;

    const toggleInterest = (interestId: string) => {
      if (selectedInterests.includes(interestId)) {
        onInterestsChange(selectedInterests.filter((i) => i !== interestId));
      } else {
        if (maxSelectedCount && selectedInterests.length >= maxSelectedCount)
          return;
        onInterestsChange([...selectedInterests, interestId]);
      }
    };

    const gridLayout = layoutType === "grid";

    const selectedStyle = (id: string) =>
      selectedInterests.includes(id)
        ? "!tw-border-[#1E28FF] tw-border-1"
        : "!tw-border-[#EDEDEB]";

    if (interestOptions?.length === 0) {
      return <></>;
    }

    const renderButton = (interest: OptionItem) => {
      const emoji = allInterests.filter(
        (i) => i.name === interest.display_en
      )?.[0]?.emoji;

      return (
        <Button
          key={interest.id}
          variant="outline"
          className={`tw-text-lg tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-2 sm:tw-p-4 tw-h-[66px] sm:tw-h-20 tw-rounded-lg ${
            gridLayout
              ? "tw-w-[109px] sm:tw-w-[119px]"
              : "tw-min-w-[80px] sm:tw-min-w-[80px]"
          } ${selectedStyle(interest.id)}`}
          onClick={() => toggleInterest(interest.id)}
        >
          {emoji && (
            <span className="tw-text-xl sm:tw-text-2xl tw-mb-1 tw-pt-3 tw-px-5">{emoji}</span>
          )}
          <span className="tw-text-xs sm:tw-text-sm tw-leading-tight tw-pb-3 tw-px-5">
            {interest.display_en}
          </span>
        </Button>
      );
    };

    if (gridLayout) {
      return (
        <div className="tw-mx-auto tw-max-w-[524px]">
          <div className="tw-grid tw-grid-cols-3 tw-gap-2 sm:tw-hidden">
            {interestOptions?.slice(0, 9).map(renderButton)}
          </div>
          <div className="tw-flex tw-justify-center tw-mt-2 sm:tw-hidden">
            {renderButton(interestOptions?.[9])}
          </div>
          <div className="tw-hidden sm:tw-block">
            <div className="tw-grid tw-grid-cols-4 tw-gap-4">
              {interestOptions?.slice(0, 8).map(renderButton)}
            </div>
            <div className="tw-flex tw-justify-center tw-gap-4 tw-mt-4">
              {interestOptions?.slice(8).map(renderButton)}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="tw-w-full tw-overflow-x-auto">
        <div className="tw-flex tw-gap-2 tw-pb-2">
          {interestOptions?.map(renderButton)}
        </div>
      </div>
    );
  }
);
