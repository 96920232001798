import { FC, useEffect, useState } from "react";
import { Button } from "../../shadcn-components/button";
import { Input } from "../../shadcn-components/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { observer } from "mobx-react-lite";
import signUpStore from "../../stores/signup-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { authStore } from "../../stores/auth-store";

const otpSchema = z.object({
  otp: z.string().length(6, "OTP must be 6 digits"),
});

type OTPFormValues = z.infer<typeof otpSchema>;
export const OTPVerificationScreen: FC = observer(() => {
  const [isLoading, setIsLoading] = useState(false);

  const [timeLeft, setTimeLeft] = useState<number>(30);
  const navigate = useNavigate();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || "";

  const form = useForm<OTPFormValues>({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: "",
    },
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const onSubmit = async (data: OTPFormValues) => {
    setIsLoading(true);
    try {
      const isSignUpRequired = await signUpStore.submitOTP(data.otp);
      if (isSignUpRequired) {
        navigate("/onboarding-profile");
      } else {
        await authStore.refreshAuth();
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-white">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="tw-p-4">
          <p className="tw-text-gray-500 tw-text-sm tw-mb-8 tw-text-left">
            Enter the verification code sent to the mobile number you have
            provided
          </p>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="tw-space-y-6"
            >
              <FormField
                control={form.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
                      <FormLabel className="tw-text-sm tw-text-gray-600">
                        OTP
                      </FormLabel>
                      <span className="tw-text-xs tw-text-gray-400">
                        {phoneNumber}
                      </span>
                    </div>
                    <FormControl>
                      <Input
                        type="text"
                        inputMode="numeric"
                        maxLength={6}
                        placeholder="23455"
                        className="tw-text-lg tw-text-left tw-tracking-[1em] "
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          field.onChange(value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <p className="tw-text-sm tw-text-gray-500 tw-text-left">
                This text should arrive within{" "}
                <span className="tw-text-blue-600">{timeLeft}s</span>
              </p>

              <Button
                type="submit"
                className="tw-w-full tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-py-6 !tw-rounded-[38px]"
              >
                Verify
              </Button>
            </form>
          </Form>
        </div>
      )}
    </div>
  );
});
