import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
} from "axios";
import { ApiError } from "./auth.types";
import { authStore } from "./auth-store";

declare module "axios" {
  export interface InternalAxiosRequestConfig {
    _retry?: boolean;
  }
}
export enum AuthRequirement {
  REQUIRED = "required", // Must be authenticated
  OPTIONAL = "optional", // Can be authenticated or not
  NONE = "none", // No auth check needed (public endpoints)
}
interface ApiRequestConfig extends AxiosRequestConfig {
  authRequirement?: AuthRequirement;
  dataPath?: string;
}

class ApiClient {
  private api: AxiosInstance;
  private static instance: ApiClient;
  private isRefreshing = false;
  private refreshPromise: Promise<void> | null = null;

  private constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 15000, // 15 seconds timeout
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.api.interceptors.response.use(
      (response) => response,
      async (error: AxiosError<ApiError>) => {
        if (!error.config) {
          return Promise.reject(this.handleError(error));
        }

        const originalRequest = error.config as InternalAxiosRequestConfig;

        // Check specifically for token expiration
        const isTokenExpired =
          error.response?.status === 401 &&
          (error.response?.data?.code === "INVALID_TOKEN" ||
            error.response?.data?.message?.toLowerCase().includes("expired"));

        if (
          isTokenExpired &&
          !originalRequest._retry &&
          !originalRequest.url?.includes("refresh-token") &&
          !originalRequest.url?.includes("auth/")
        ) {
          if (this.isRefreshing) {
            try {
              if (this.refreshPromise) {
                await this.refreshPromise;
                return this.api(originalRequest);
              }
            } catch (error) {
              return Promise.reject(error);
            }
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          try {
            this.refreshPromise = this.refreshToken();
            await this.refreshPromise;
            return this.api(originalRequest);
          } catch (refreshError) {
            authStore.user = null;
            return Promise.reject(
              this.handleError(refreshError as AxiosError<ApiError>)
            );
          } finally {
            this.isRefreshing = false;
            this.refreshPromise = null;
          }
        }

        return Promise.reject(this.handleError(error));
      }
    );
  }

  private handleError(error: AxiosError<ApiError>): ApiError {
    if (error.response?.data) {
      return {
        message: error.response.data.message || "An unexpected error occurred",
        code: error.response.data.code || "UNKNOWN_ERROR",
        status: error.response.status,
      };
    }

    // Network errors or other issues
    return {
      message: error.message || "Network error occurred",
      code: "NETWORK_ERROR",
      status: 500,
    };
  }

  static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  private async refreshToken(): Promise<void> {
    try {
      await this.api.post("/auth/refresh-token");
    } catch (error) {
      throw error;
    }
  }

  async get<T>(
    url: string,
    config: ApiRequestConfig = {}
  ): Promise<T> {
    try {
      const response: AxiosResponse = await this.api.get(url, config);

      if (config.dataPath) {
        const result = config.dataPath
          .split(".")
          .reduce((obj, key) => obj?.[key], response.data);

        if (result === undefined) {
          throw new Error(`Could not find data at path: ${config.dataPath}`);
        }

        // Two-step assertion through unknown
        return result as unknown as T;
      }

      // Two-step assertion through unknown for direct responses
      return response.data as unknown as T;
    } catch (error) {
      throw this.handleError(error as AxiosError<ApiError>);
    }
  }
  async post<T>(url: string, data?: unknown, config = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.post(url, data, config);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError<ApiError>);
    }
  }

  async put<T>(url: string, data?: unknown, config = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.put(url, data, config);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError<ApiError>);
    }
  }

  async patch<T>(url: string, data?: unknown, config = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.patch(
        url,
        data,
        config
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError<ApiError>);
    }
  }

  async delete<T>(url: string, config = {}): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.delete(url, config);
      return response.data;
    } catch (error) {
      throw this.handleError(error as AxiosError<ApiError>);
    }
  }
}

export const apiClient = ApiClient.getInstance();
