import { FC, useEffect, useState } from "react";

import { ChevronLeft, Search, X } from "lucide-react";
import {
  AddressSuggestion,
  getAddressSuggestions,
  getPlaceDetails,
  LocationDetails,
} from "../api/address/autocomplete-service";
import { useDebounce } from "use-debounce";
import { Input } from "../../../shadcn-components/input";
import { Button } from "../../../shadcn-components/button";

interface LocationSearchViewProps {
  onClose: () => void;
  onSelect: (location: LocationDetails) => void;
  initialValue?: string;
}

export const LocationSearchView: FC<LocationSearchViewProps> = ({
  onClose,
  onSelect,
  initialValue,
}: LocationSearchViewProps) => {
  const [searchQuery, setSearchQuery] = useState(initialValue || "");
  const [debouncedQuery] = useDebounce(searchQuery, 300);
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedQuery.length < 3) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      try {
        const response = await getAddressSuggestions(debouncedQuery);
        if (response.data) {
          setSuggestions(response.data);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [debouncedQuery]);

  const handleSelect = async (suggestion: AddressSuggestion) => {
    try {
      setLoading(true);
      const detailsResponse = await getPlaceDetails(
        suggestion.placeId,
        suggestion
      );

      if (detailsResponse.data) {
        onSelect(detailsResponse.data);
        onClose();
      } else {
        console.error("Failed to get place details:", detailsResponse.error);
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-white tw-z-50 tw-flex tw-flex-col !tw-mt-0">
      {/* Header */}
      <div>
        <div className="tw-flex tw-items-center tw-gap-2 tw-px-1 tw-mb-8 tw-mt-4">
          <Button
            variant="secondary"
            size="icon"
            onClick={onClose}
            className="tw-mr-4 !tw-rounded-3xl"
          >
            <ChevronLeft className="tw-h-5 tw-w-5" />
          </Button>
          <h1 className="tw-text-xl tw-font-bold tw-truncate">
            Set your Location
          </h1>
        </div>

        {/* Search Input */}
        <div className="tw-px-4 tw-pb-3 tw-relative">
          <Search className="tw-absolute tw-left-5 tw-top-2.5 tw-h-5 tw-w-5 tw-text-gray-400" />
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search location"
            className="tw-pl-10 tw-pr-10"
          />
          {searchQuery && (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSearchQuery("")}
              className="tw-absolute tw-right-6 tw-top-2 tw-h-6 tw-w-6"
            >
              <X className="tw-h-4 tw-w-4" />
            </Button>
          )}
        </div>
      </div>

      <div className="tw-flex-1 tw-overflow-auto">
        {loading ? (
          <div className="tw-p-4 tw-text-center tw-text-gray-500">
            Searching locations...
          </div>
        ) : searchQuery.length < 3 ? (
          <div className="tw-p-4 tw-text-center tw-text-gray-500">
            Type at least 3 characters to search...
          </div>
        ) : suggestions.length === 0 ? (
          <div className="tw-p-4 tw-text-center tw-text-gray-500">
            No locations found
          </div>
        ) : (
          <div className="tw-divide-y">
            {suggestions.map((suggestion) => (
              <button
                key={suggestion.placeId}
                onClick={() => handleSelect(suggestion)}
                className="tw-w-full tw-text-left tw-p-4 hover:tw-bg-gray-50 tw-flex tw-flex-col tw-gap-1"
              >
                <span className="tw-font-medium">
                  {suggestion.primaryAddress}
                </span>
                <span className="tw-text-sm tw-text-gray-500">
                  {suggestion.secondaryAddress}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
