import { apiClient, AuthRequirement } from "../../../stores/api-client";
import { User, AdminUser } from "../../../stores/auth.types";
import axios from "axios";
import { ManageCommunityFormValues } from "../manage-community.schema";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export interface Community {
  id: string;
  title: string;
  handle: string;
  description: string;
  location: string;
  followerCount: number;
  profileImageUrl: string;
  bannerImageUrls: string;
  categories: string[];
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
  };
  isUserNotLoggedIn?: boolean;
  isLoggedInUserAdmin?: boolean;
  isLoggedInUserFollower?: boolean;
}

export interface CommunityFormData {
  title: string;
  handle: string;
  description: string;
}

export interface PresignedUrlResponse {
  uploadUrl: string;
  keyID: string;
}

export interface CreateCommunityInput extends CommunityFormData {
  profilePhoto?: File;
  coverPhoto?: File;
  categories: string[];
  location?: string;
  socialMedia?: {
    instagram?: string;
    whatsapp?: string;
    youtube?: string;
    twitter?: string;
    website?: string;
  };
}

const getHeaders = (): Record<string, string> => ({
  "Content-Type": "application/json",
});

export const communityApi = {
  getCommunityByHandle: async (handle: string): Promise<Community> => {
    try {
      const data = await apiClient.get<Community>(
        `/communities/handle/${handle}`,
        {
          dataPath: "data",
          authRequirement: AuthRequirement.OPTIONAL,
        }
      );
      return data;
    } catch (error) {
      throw new Error(
        `Failed to fetch community: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  getCommunityById: async (id: string): Promise<Community> => {
    try {
      const response = await axios.get(`${apiBaseUrl}/communities/${id}`, {
        headers: getHeaders(),
      });
      return response.data.data;
    } catch (error) {
      throw new Error(
        `Failed to fetch community: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  getCommunityFollowers: async (id: string): Promise<User[]> => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${id}/followers`,
        {
          headers: getHeaders(),
        }
      );
      return response.data.items;
    } catch (error) {
      throw new Error(
        `Failed to fetch community followers: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  getCommunityAdmins: async (id: string): Promise<AdminUser[]> => {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${id}/admins`,
        {
          headers: getHeaders(),
        }
      );
      return response.data.items;
    } catch (error) {
      throw new Error(
        `Failed to fetch community admins: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  createCommunity: async (data: CreateCommunityInput): Promise<boolean> => {
    try {
      let profileImageKey = null;
      let coverImageKey = null;

      // Upload profile photo if exists
      if (data.profilePhoto) {
        profileImageKey = await communityApi.uploadImageWithPresignedUrl(
          data.profilePhoto,
          "profile"
        );
      }

      // Upload cover photo if exists
      if (data.coverPhoto) {
        coverImageKey = await communityApi.uploadImageWithPresignedUrl(
          data.coverPhoto,
          "cover"
        );
      }

      // Prepare API payload
      const apiPayload = {
        title: data.title,
        handle: data.handle,
        description: data.description,
        categories: data.categories,
        socialMedia: data.socialMedia,
        profileImageUrl: profileImageKey,
        bannerImageUrls: coverImageKey,
        location: data.location,
      };

      const response = await apiClient.put<string>(
        `${apiBaseUrl}/communities`,
        apiPayload,
        {
          headers: getHeaders(),
        }
      );

      return !!response;
    } catch (error) {
      console.error("Error creating community:", error);
      return false;
    }
  },

  getPresignedUrl: async (
    file: File,
    imageType: "cover" | "profile"
  ): Promise<PresignedUrlResponse> => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/get-image-upload-presigned-url`,
        {
          entityType:
            imageType === "cover" ? "community_banner" : "community_logo",
          fileType: file.type,
          fileSize: file.size,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to get presigned URL");
      }
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to get presigned URL: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  uploadImageWithPresignedUrl: async (
    file: File,
    imageType: "cover" | "profile"
  ): Promise<string | null> => {
    try {
      // Get presigned URL
      const presignedData = await communityApi.getPresignedUrl(file, imageType);

      // Upload to S3
      const uploadResponse = await axios.put(presignedData.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status !== 200) {
        throw new Error("Failed to upload image");
      }

      return presignedData.keyID;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  },

  updateCommunity: async (
    id: string,
    updates: Partial<ManageCommunityFormValues>
  ) => {
    try {
      let profileImageKey = null;
      let coverImageKey = null;

      // Upload profile photo if exists
      if (updates.profilePhoto && updates.profilePhoto instanceof File) {
        profileImageKey = await communityApi.uploadImageWithPresignedUrl(
          updates.profilePhoto,
          "profile"
        );
      }

      // Upload cover photo if exists
      if (updates.coverPhoto && updates.coverPhoto instanceof File) {
        coverImageKey = await communityApi.uploadImageWithPresignedUrl(
          updates.coverPhoto,
          "cover"
        );
      }

      const apiPayload = {
        title: updates.title,
        handle: updates.handle,
        description: updates.description,
        categories: updates.categories,
        socialMedia: updates.socialMedia,
        ...(profileImageKey !== null && { profileImageUrl: profileImageKey }),
        ...(coverImageKey !== null && { bannerImageUrls: coverImageKey }),
        location: updates.location,
      };
      const resp = await apiClient.patch(
        `${apiBaseUrl}/communities/${id}`,
        apiPayload,
        {
          headers: getHeaders(),
        }
      );
      return !!resp;
    } catch (error) {
      console.error("Error updating community:", error);
      return false;
    }
  },
};
