import { PresignedUrlResponse } from "../../../communities/api/community-api";
import { apiClient } from "../../../../stores/api-client";
import { EventData, User } from "../../stores/event-store";
import axios from "axios";

interface EventCreateResponse {
  data: EventData;
}

export interface EventGuestsResponse {
  attendees: EventAttendee[];
  lastEvaluatedKey: string | null;
}

export interface EventAttendee {
  eventId: string;
  registrationId: string;
  status: "active" | "checkedIn" | "cancelled";
  userId: string;
  userDetails: {
    status: string;
    user: User;
  };
}

export const eventApi = {
  registerForEvent: async (eventId: string): Promise<void> => {
    try {
      await apiClient.post(`/events/${eventId}/register`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error) {
      throw new Error(
        `Failed to register for event: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  cancelRegistration: async (eventId: string): Promise<void> => {
    try {
      await apiClient.patch(`/events/${eventId}/cancel-registration`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error) {
      throw new Error(
        `Failed to cancel registration for event: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  create: async (payload: any): Promise<any> => {
    try {
      const response = await apiClient.post<EventCreateResponse>(
        "/events",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Event API Create Error:", error);
      throw new Error(
        error instanceof Error ? error.message : "An error occurred"
      );
    }
  },

  getPresignedUrl: async (file: File): Promise<PresignedUrlResponse> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/get-image-upload-presigned-url`,
        {
          entityType: "event",
          fileType: file.type,
          fileSize: file.size,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to get presigned URL");
      }
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to get presigned URL: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },

  uploadImageWithPresignedUrl: async (file: File): Promise<string | null> => {
    try {
      // Get presigned URL
      const presignedData = await eventApi.getPresignedUrl(file);

      // Upload to S3
      const uploadResponse = await axios.put(presignedData.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status !== 200) {
        throw new Error("Failed to upload image");
      }

      return presignedData.keyID;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  },

  getEventGuests: async (id: string): Promise<EventGuestsResponse> => {
    if (!id) {
      console.error("Event ID is undefined.");
    }
    try {
      const data = await apiClient.get<EventGuestsResponse>(
        `/events/${id}/attendees`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          dataPath: "data",
        }
      );
      return data;
    } catch (error) {
      throw new Error("Failed to get guests");
    }
  },

  checkInUser: async (eventId: string, userId: string) => {
    try {
      await apiClient.post(`/events/${eventId}/checkin/${userId}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error) {
      throw new Error("Failed to check in");
    }
  },
};
