import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { z } from "zod";
import { Community } from "../../../stores/community-store";
import { normalizeDateToMinute } from "../../../utils/datetime-utils";
import { eventApi } from "../api/address/event-api";

export interface LocationValue {
  primaryAddress: string;
  secondaryAddress: string;
  latitude: number;
  longitude: number;
}

export type EventFormValues = {
  communityId: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  city: string;
  location: LocationValue;
  description: string;
  totalCapacity: string;
  price?: number;
  logo?: string;
};

export const eventFormSchema = z.object({
  communityId: z.string({ required_error: "Please select a community" }),
  name: z.string().min(1, "Event name is required"),
  startDateTime: z.preprocess((arg) => {
    if (arg instanceof Date) return arg.toISOString();
    return arg;
  }, z.string().datetime()),
  endDateTime: z.preprocess((arg) => {
    if (arg instanceof Date) return arg.toISOString();
    return arg;
  }, z.string().datetime()),
  city: z.string().min(1, "City is required"),
  location: z.object({
    primaryAddress: z.string().min(3),
    secondaryAddress: z.string().min(3),
    latitude: z.number(),
    longitude: z.number(),
  }),
  description: z.string().min(3, "Description must be at least 3 characters"),
  totalCapacity: z.string(),
  price: z.number().optional(),
  logo: z.string().optional(),
});

export const validateDates = (data: z.infer<typeof eventFormSchema>) => {
  const startDate = new Date(data.startDateTime);
  const endDate = new Date(data.endDateTime);

  if (endDate <= startDate) {
    throw new Error("End date must be after start date");
  }
  return data;
};

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class CreateEventStore {
  formData: EventFormValues = {
    communityId: "",
    name: "",
    startDateTime: normalizeDateToMinute(new Date()).toISOString(),
    endDateTime: normalizeDateToMinute(
      new Date(Date.now() + 3600000)
    ).toISOString(),
    city: "",
    location: {
      primaryAddress: "",
      secondaryAddress: "",
      latitude: 0,
      longitude: 0,
    },
    description: "",
    totalCapacity: "",
    price: 0,
  };
  @observable isSubmitting = false;
  @observable error: string | null = null;
  @observable imagePreview: string | null = null;
  @observable imageFile: File | null = null;
  @observable adminCommunities: Community[] = [];
  @observable isLoadingCommunities = false;
  @observable isUploadingImage = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setFormField<K extends keyof EventFormValues>(
    field: K,
    value: EventFormValues[K]
  ) {
    this.formData[field] = value;
  }

  @action
  setImageFile(file: File | null) {
    this.imageFile = file;
    // If clearing the file, also clear the preview
    if (!file) {
      this.imagePreview = null;
    }
  }

  @action
  setImagePreview(imageUrl: string | null) {
    // Revoke old blob URL if it exists
    if (this.imagePreview?.startsWith("blob:")) {
      URL.revokeObjectURL(this.imagePreview);
    }
    this.imagePreview = imageUrl;
  }

  @action
  resetForm() {
    if (this.imagePreview?.startsWith("blob:")) {
      URL.revokeObjectURL(this.imagePreview);
    }
    this.formData = {
      communityId: "",
      name: "",
      startDateTime: new Date().toISOString(),
      endDateTime: new Date().toISOString(),
      city: "",
      location: {
        primaryAddress: "",
        secondaryAddress: "",
        latitude: 0,
        longitude: 0,
      },
      description: "",
      totalCapacity: "",
    };
    this.imagePreview = null;
    this.imageFile = null;
    this.error = null;
  }

  @action
  uploadImage = async (file: File): Promise<string | null> => {
    try {
      this.isUploadingImage = true;
      return await eventApi.uploadImageWithPresignedUrl(file);
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : "Upload failed";
      });
      return null;
    } finally {
      runInAction(() => {
        this.isUploadingImage = false;
      });
    }
  };

  @action
  submitForm = async () => {
    try {
      this.isSubmitting = true;
      this.error = null;

      let bannerImageUrl: string | undefined;
      if (this.imageFile) {
        const imageKey = await this.uploadImage(this.imageFile);
        if (imageKey) {
          bannerImageUrl = imageKey;
        }
      }

      const validatedData = eventFormSchema.parse({
        ...this.formData,
        logo: bannerImageUrl,
      });
      validateDates(validatedData);

      const eventData = await eventApi.create(validatedData);

      if (eventData?.eventId) {
        runInAction(() => {
          this.resetForm();
        });
        return eventData.eventId;
      }
      return null;
    } catch (error) {
      runInAction(() => {
        this.error =
          error instanceof Error ? error.message : "An error occurred";
      });
      throw error;
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  };

  @action
  getAdminCommunities = async () => {
    try {
      this.isLoadingCommunities = true;
      const response = await axios.get(
        `${apiBaseUrl}/communities?isAdmin=true`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      runInAction(() => {
        this.adminCommunities = response.data.items;
        this.isLoadingCommunities = false;
      });
    } catch (error) {
      console.error("Failed to fetch communities:", error);
      runInAction(() => {
        this.adminCommunities = [];
        this.isLoadingCommunities = false;
      });
    }
  };
}

export const createEventStore = new CreateEventStore();
