import React from "react";
import { Button } from "../shadcn-components/button";
import whatsappIcon from "../static/whatsapp.svg";
import { ChevronRight, PhoneCall } from "lucide-react";

const ContactOptions: React.FC = () => {
  return (
    <div className="tw-p-4">
      <div className="tw-border tw-border-gray-200 tw-rounded-2xl tw-bg-white">
        <Button
          variant="ghost"
          className="tw-flex tw-w-full tw-items-center tw-justify-between !tw-py-8 tw-px-6 tw-rounded-t-2xl hover:tw-bg-gray-50"
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-[#25D366] tw-rounded-xl">
              <img
                src={whatsappIcon}
                className="tw-w-7 tw-h-7"
                alt="WhatsApp"
              />
            </div>
            <div>
              <h2 className="tw-font-semibold tw-text-base !tw-text-left">Chat with us</h2>
              <p className="tw-text-gray-400 tw-text-sm">
                We're here to help 24/7
              </p>
            </div>
          </div>
          <ChevronRight className="tw-w-5 tw-h-5 tw-text-gray-400" />
        </Button>
        <div className="tw-border-t tw-border-gray-200"></div>
        <Button
          variant="ghost"
          className="tw-flex tw-w-full tw-items-center tw-justify-between !tw-py-8 tw-px-6 tw-rounded-b-2xl hover:tw-bg-gray-50"
        >
          <div className="tw-flex tw-items-center tw-gap-4">
            <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-primary tw-rounded-xl">
              <PhoneCall className="tw-w-7 tw-h-7 tw-text-white" />
            </div>
            <div>
              <h2 className="tw-font-semibold tw-text-base !tw-text-left">Call Us</h2>
              <p className="tw-text-gray-400 tw-text-sm">
                We'd love to hear from you
              </p>
            </div>
          </div>
          <ChevronRight className="tw-w-5 tw-h-5 tw-text-gray-400" />
        </Button>
      </div>
    </div>
  );
};

export default ContactOptions;
