import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { communityApi } from "../components/communities/api/community-api";
import { EventData } from "../components/events/stores/event-store";
import { AdminUser } from "./manage-community.store";
import { z } from "zod";
import signUpStore from "./signup-store";

export interface Community {
  id: string;
  title: string;
  handle: string;
  description: string;
  location: string;
  followerCount: number;
  profileImageUrl: string;
  bannerImageUrls: string;
  categories: string[];
  socialMedia?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
    whatsapp?: string;
  };
  isUserNotLoggedIn?: boolean;
  isLoggedInUserAdmin?: boolean;
  isLoggedInUserFollower?: boolean;
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export enum CommunityFollowAction {
  follow = "follow",
  unFollow = "unfollow",
}

export interface FollowerUser {
  accountName: string;
  handle: string;
  userId: string;
  profilePicture?: string;
}
export const formSchema = z.object({
  title: z.string().min(2, {
    message: "Community title must be at least 2 characters.",
  }),
  handle: z
    .string()
    .min(5, "Must be at least 5 characters long")
    .max(15, "Maximum of 15 characters")
    .refine((value) => !/\s/.test(value), {
      message: "Username cannot contain spaces or whitespace characters",
    })
    .refine(
      async (value) => {
        if (!value) return false;
        try {
          return await signUpStore.isUserHandleAvailable(value);
        } catch {
          return false;
        }
      },
      {
        message: "Username is already taken",
      }
    ),
  description: z.string().max(300, {
    message: "Bio must not exceed 300 characters.",
  }),
  location: z.string().optional(),
  coverPhoto: z.instanceof(File).optional(),
  profilePhoto: z.instanceof(File).optional(),
  categories: z.string().array().length(1),
  socialMedia: z.object({
    instagram: z.string().optional(),
    whatsapp: z.string().optional(),
    youtube: z.string().optional(),
    twitter: z.string().optional(),
    website: z.string().optional(),
  }),
});

export type FormValues = z.infer<typeof formSchema>;

class CommunityStore {
  @observable communityData: Community | null = null;
  @observable isProfileLoading = false;
  @observable currentCommunityViewName: string = "community name";
  @observable adminCommunities: Community[] = [];
  @observable isAdminCommunitiesLoading = false;
  @observable followingCommunities: Community[] = [];
  @observable isFollowingCommunitiesLoading = false;
  @observable followers: FollowerUser[] = [];
  @observable isFollowersLoading = false;
  @observable isSubmitting = false;
  @observable isLoadingExploreCommunities = false;
  @observable exploreCommunities: Community[] = [];
  @observable eventsData: EventData[] = [];
  @observable isLoadingAdminsList = false;
  @observable adminsList: AdminUser[] = [];
  @observable followersByCommunityId: Map<string, FollowerUser[]> = new Map<
    string,
    FollowerUser[]
  >();
  @observable isUploadingProfileImage = false;
  @observable isUploadingCoverImage = false;
  @observable uploadedProfileImageKey: string | null = null;
  @observable uploadedCoverImageKey: string | null = null;
  @observable usernameError: string | null = null;
  @observable isUsernameAvailable: boolean | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  createCommunity = async (data: FormValues) => {
    this.isSubmitting = true;
    const isCreationSuccess = await communityApi.createCommunity(data);
    runInAction(() => {
      if (isCreationSuccess) {
        this.isSubmitting = false;
      }
    });
    return isCreationSuccess;
  };

  @action
  setCurrentCommunityViewName(viewName?: string) {
    if (viewName) {
      this.currentCommunityViewName = viewName;
    }
  }

  @action
  setProfileLoading = (isLoading: boolean) =>
    (this.isProfileLoading = isLoading);

  @action
  getCommunity = async (handle: string) => {
    this.isProfileLoading = true;
    try {
      const communityData = await communityApi.getCommunityByHandle(handle);
      runInAction(() => {
        this.communityData = communityData;
      });
    } catch (error) {
      console.error("Failed to fetch community:", error);
      runInAction(() => {
        this.communityData = null;
      });
    } finally {
      runInAction(() => {
        this.isProfileLoading = false;
      });
    }
  };

  @action
  getEventsOfCommunity = async (id: string) => {
    const response = await axios.get(`${apiBaseUrl}/communities/${id}/events`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) console.log("Failed to get events");
    runInAction(() => {
      this.eventsData = response.data.data;
    });
  };

  @action
  getCommunitiesByFilter = async () => {
    this.isLoadingExploreCommunities = true;
    const response = await axios.get(`${apiBaseUrl}/communities`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.exploreCommunities = response.data.items;
      this.isLoadingExploreCommunities = false;
    });
  };

  @action
  getCommunityFollowers = async (ids: string[]) => {
    this.isFollowersLoading = true;
    ids.forEach(async (id) => {
      const data = await communityApi.getCommunityFollowers(id);
      runInAction(() => {
        // this.followers = data;
        this.followersByCommunityId.set(id, data);
        this.isFollowersLoading = false;
      });
    });
  };

  @action
  followOrUnfollow = async (action: CommunityFollowAction) => {
    const response = await axios.put(
      `${apiBaseUrl}/communities/${this.communityData?.id}/followers`,
      {
        action: action,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    runInAction(() => {
      console.log(response.status);
    });
  };

  @action
  getAdmins = async (communityId?: string) => {
    if (!communityId) {
      return;
    }
    this.isLoadingAdminsList = true;
    try {
      const response = await axios.get(
        `${apiBaseUrl}/communities/${communityId}/admins`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      runInAction(() => {
        this.adminsList = response.data.items;
      });
    } catch (error) {
      console.error("Error fetching community data:", error);
    } finally {
      runInAction(() => {
        this.isLoadingAdminsList = false;
      });
    }
  };

  @action
  setUsernameError = (error: string | null) => {
    this.usernameError = error;
  };

  @action
  setIsUsernameAvailable = (isAvailable: boolean | null) => {
    this.isUsernameAvailable = isAvailable;
  };
}

export const communityStore = new CommunityStore();
