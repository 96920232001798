import { useEffect, useState } from "react";
import { Button } from "../../../shadcn-components/button";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { manageEventStore } from "../stores/manage-event.store";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DateTimePicker } from "../../../shadcn-components/datetime-picker";
import { ChevronDown, ChevronUp, MapPin } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../shadcn-components/form";
import { Input } from "../../../shadcn-components/input";
import { Textarea } from "../../../shadcn-components/textarea";
import { EventFormValues, eventFormSchema } from "../stores/create-event-store";
import { toast } from "../../../hooks/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn-components/select";
import { EventBanner } from "./event-banner";

interface EventImageState {
  bannerUrl: string | null;
  bannerFile: File | null;
}

const ManageEventForm = observer(() => {
  const { eventId } = useParams<{ eventId: string }>();
  const {
    editEventData,
    isLoading,
    isSubmitting,
    updateEvent,
    getEventData,
    adminCommunities,
    getAdminCommunities,
  } = manageEventStore;
  const [imageState, setImageState] = useState<EventImageState>({
    bannerUrl: null,
    bannerFile: null,
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const form = useForm<EventFormValues>({
    resolver: zodResolver(eventFormSchema),
    defaultValues: {
      communityId: "",
      name: "",
      startDateTime: new Date().toISOString(),
      endDateTime: new Date(Date.now() + 3600000).toISOString(),
      description: "",
      city: "",
      location: {
        primaryAddress: "",
        secondaryAddress: "",
        latitude: 0,
        longitude: 0,
      },
      totalCapacity: "",
      logo: "",
    },
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        await getAdminCommunities();
      } catch (error) {
        console.error("Error fetching communities:", error);
      }
    };
    fetch();
    if (eventId) {
      getEventData(eventId);
    }
  }, [eventId]);

  useEffect(() => {
    if (editEventData) {
      form.reset({
        communityId: editEventData.communityId,
        name: editEventData.name,
        startDateTime: editEventData.startDateTime,
        endDateTime: editEventData.endDateTime,
        description: editEventData.description,
        city: editEventData.city,
        location: editEventData.location,
        totalCapacity: editEventData.totalCapacity?.toString() || "",
      });
    }
  }, [editEventData]);

  useEffect(() => {
    if (editEventData?.logo) {
      setImageState({
        bannerUrl: editEventData.logo,
        bannerFile: null,
      });
      setIsInitialLoad(true); // This is an S3 URL
    }
  }, [editEventData]);

  const handleBannerChange = (file: File, previewUrl: string): void => {
    setImageState({
      bannerUrl: previewUrl,
      bannerFile: file,
    });
    setIsInitialLoad(false); // No longer using S3 URL after upload
    form.setValue("logo", previewUrl);
  };

  const navigate = useNavigate();
  const onSubmit = async (formData: EventFormValues) => {
    try {
      const changes: Partial<EventFormValues> = {};

      Object.entries(formData).forEach(([key, value]) => {
        const originalValue =
          editEventData?.[key as keyof typeof editEventData];
        if (key === "logo" && !imageState.bannerFile) {
          return;
        }

        // Check if value is different
        if (JSON.stringify(value) !== JSON.stringify(originalValue)) {
          // Perform a type check to ensure the value matches EventFormValues' property types
          if (value === undefined || typeof value === typeof originalValue) {
            changes[key as keyof EventFormValues] = value as any;
          }
        }
      });

      await updateEvent(changes, imageState.bannerFile || undefined);
      navigate(`/e/${eventId}`);
      toast({
        title: "Success",
        description: "Event updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update event",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-bg-white">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="tw-flex tw-flex-col tw-flex-1 tw-space-y-6 tw-p-4"
        >
          <FormField
            control={form.control}
            name="logo"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-sm tw-font-medium">
                  Event Banner
                </FormLabel>
                <FormControl>
                  <EventBanner
                    bannerUrl={imageState.bannerUrl}
                    onBannerChange={handleBannerChange}
                    isInitialLoad={isInitialLoad}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="communityId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Select Community</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                  }}
                  value={field.value}
                >
                  <SelectTrigger className="tw-rounded-full">
                    <SelectValue placeholder="Select a community" />
                  </SelectTrigger>
                  <SelectContent>
                    {adminCommunities.map((community) => (
                      <SelectItem
                        key={community.id}
                        value={community.id.toString()}
                      >
                        {community.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-sm tw-font-medium tw-text-red-500">
                  Event Title *
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    className="tw-rounded-full"
                    placeholder="Enter event title"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="tw-space-y-4">
            <div className="tw-flex tw-items-start tw-gap-4">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
                <ChevronUp className="tw-h-5 tw-w-5" />
                <div className="tw-h-6 tw-w-[1px] tw-bg-gray-300" />
                <ChevronDown className="tw-h-5 tw-w-5" />
              </div>
              <div className="tw-flex-1 tw-space-y-4">
                <div>
                  <div className="tw-font-medium">Starts</div>
                  <FormField
                    control={form.control}
                    name="startDateTime"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <DateTimePicker
                            value={new Date(field.value)}
                            onChange={(date) => {
                              if (date) {
                                field.onChange(date.toISOString());
                              }
                            }}
                            use12HourFormat
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <div className="tw-font-medium">Ends</div>
                  <FormField
                    control={form.control}
                    name="endDateTime"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <DateTimePicker
                            value={new Date(field.value)}
                            onChange={(date) => {
                              if (date) {
                                field.onChange(date.toISOString());
                              }
                            }}
                            use12HourFormat
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <div className="tw-flex tw-items-start tw-gap-4">
                  <MapPin className="tw-h-5 tw-w-5 tw-mt-1" />
                  <div className="tw-flex-1">
                    <div className="tw-font-medium">
                      {field.value.primaryAddress}
                    </div>
                    <div className="tw-text-sm tw-text-gray-500">
                      {field.value.secondaryAddress}
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="tw-text-blue-600"
                  >
                    Change
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="tw-text-sm tw-font-medium tw-text-red-500">
                  Add Description *
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="tw-min-h-[100px] tw-resize-none"
                    placeholder="Describe your event"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="tw-mt-auto">
            <Button
              type="submit"
              className="tw-w-full tw-bg-blue-600 hover:tw-bg-blue-700 tw-rounded-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Update"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
});
export default ManageEventForm;
