import { observer } from "mobx-react-lite";
import { Card, CardContent } from "../shadcn-components/card";
import { ScrollArea } from "../shadcn-components/scroll-area";
import calendarStore from "../stores/calendar-store";
import { format, isToday, isTomorrow } from "date-fns";
import { Button } from "../shadcn-components/button";
import { EventData } from "./events/stores/event-store";
import { FC, useEffect } from "react";
import { getCommunityLogo, getEventImage } from "../utils/image-utils";
import { Separator } from "../shadcn-components/separator";
interface GroupedEvents {
  [key: string]: EventData[];
}

interface EventCardsProps {
  filter: "past" | "future" | "all";
}

export const EventCards: FC<EventCardsProps> = observer(({ filter }) => {
  const { eventsOfUser, getEventsOfUser } = calendarStore;

  useEffect(() => {
    const fetch = async () => {
      await getEventsOfUser(filter);
    };
    fetch();
  }, []);

  const getDateDisplay = (date: Date) => {
    try {
      if (isToday(date)) {
        return "TODAY";
      }
      if (isTomorrow(date)) {
        return "TOMORROW";
      }
      return format(date, "MMM d, yyyy").toUpperCase();
    } catch (error) {
      return "DATE ERROR";
    }
  };

  const groupedEvents = eventsOfUser.reduce<GroupedEvents>((acc, event) => {
    try {
      const eventDate = new Date(event.startDateTime);
      if (isNaN(eventDate.getTime())) {
        console.error("Invalid date:", event.startDateTime);
        return acc;
      }
      const timeKey = format(eventDate, "HH:mm a");
      const dateTimeKey = `${format(eventDate, "yyyy-MM-dd")}T${timeKey}`;
      if (!acc[dateTimeKey]) {
        acc[dateTimeKey] = [];
      }
      acc[dateTimeKey].push(event);
    } catch (error) {
      console.error("Error processing event:", error);
    }
    return acc;
  }, {});

  return (
    <ScrollArea className="tw-h-[calc(100vh-200px)] tw-w-full">
      <div className="tw-space-y-3">
        {Object.entries(groupedEvents).map(([dateTimeKey, events]) => {
          const [dateStr, timeStr] = dateTimeKey.split("T");
          const date = new Date(dateStr);

          if (isNaN(date.getTime())) {
            return null;
          }

          return (
            <Card
              key={dateTimeKey}
              className="tw-bg-white tw-rounded-xl tw-border-[#FFFFFF]"
            >
              <CardContent className="tw-px-4 tw-pt-3 tw-space-y-3">
                <div className="tw-text-sm tw-font-bold tw-text-left">
                  {getDateDisplay(date)}, {timeStr}
                </div>
                <div>
                  {events.map((event, index) => (
                    <div key={event.eventId}>
                      <div
                        className={`tw-flex tw-gap-3 ${
                          index === 0 ? `tw-pb-4` : "tw-pt-4"
                        }`}
                      >
                        <div className="tw-w-[82px] tw-h-[102px] tw-rounded-lg tw-overflow-hidden">
                          <img
                            src={event.logo ? getEventImage(event.logo) : ""}
                            alt={event.name}
                            className="tw-w-full tw-h-full tw-object-cover"
                          />
                        </div>
                        <div className="tw-flex-1">
                          <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
                            <div className="tw-w-6 tw-h-6 tw-rounded-full tw-bg-purple-100">
                              <img
                                src={getCommunityLogo(
                                  event.communityDetails?.profileImageUrl
                                )}
                                alt={event.communityDetails?.title}
                                className="tw-w-full tw-h-full tw-rounded-full"
                              />
                            </div>
                            <span className="tw-text-xs tw-font-medium tw-text-[#131315]">
                              By {event.communityDetails?.title}
                            </span>
                          </div>
                          <h3 className="tw-text-lg tw-font-semibold tw-mb-2 tw-text-left tw-line-clamp-2">
                            {event.name}
                          </h3>
                          <div className="tw-flex tw-items-center tw-gap-1 tw-text-gray-600 tw-mb-2">
                            <span className="tw-text-red-500">📍</span>
                            <span className="tw-text-sm">
                              {event.location.primaryAddress}
                            </span>
                          </div>
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Button
                              variant="outline"
                              className="!tw-rounded-[38px] tw-h-8 tw-text-sm !tw-px-2 !tw-py-1 !tw-border-[#131315] tw-font-semibold"
                            >
                              View Ticket
                            </Button>
                          </div>
                        </div>
                      </div>
                      {index < events.length - 1 && (
                        <Separator className="tw-border-[#1313151A] tw-border" />
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </ScrollArea>
  );
});
