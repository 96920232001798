import { observer } from "mobx-react-lite";
import { FC } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/card";
import { useNavigate } from "react-router-dom";
import { Community } from "../../stores/community-store";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn-components/tooltip";

export const CommunityCard: FC<{ community: Community }> = observer(
  ({ community }) => {
    const navigate = useNavigate();
    const onCardClick = () => {
      navigate(`/${community.handle}`);
    };
    return (
      <Card
        className="tw-overflow-hidden tw-border-custom-border hover:tw-cursor-pointer tw-h-[300px]"
        onClick={onCardClick}
      >
        <div className="tw-relative">
          <img
            src={community.bannerImageUrls}
            alt={`${community.title} banner`}
            className="tw-w-full tw-h-32 tw-object-cover"
          />
          <img
            src={community.profileImageUrl}
            alt={community.title}
            className="tw-absolute tw-bottom-0 tw-left-4 tw-transform tw-translate-y-1/2 tw-w-18 tw-h-18 tw-object-cover"
          />
        </div>
        <CardHeader className="tw-pt-12 tw-pb-2 tw-px-4">
          <div className="tw-flex tw-items-center tw-space-x-2 tw-min-w-0">
            <div className="tw-flex tw-items-center tw-min-w-0">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <CardTitle className="tw-text-base tw-m-0 tw-leading-6 tw-truncate tw-max-w-[200px] hover:tw-cursor-default">
                      {community.title}
                    </CardTitle>
                  </TooltipTrigger>
                  <TooltipContent
                    side="bottom"
                    align="start"
                    className="tw-max-w-xs"
                  >
                    <div className="tw-max-h-[200px] tw-overflow-y-auto">
                      <p className="tw-text-left tw-whitespace-pre-wrap tw-break-words">
                        {community.title}
                      </p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <CardDescription className="tw-m-0 tw-text-sm tw-leading-6 tw-truncate tw-shrink">
              {community.handle}
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent className="tw-pb-4 tw-px-4 tw-text-sm">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <p className="tw-text-left tw-truncate hover:tw-cursor-default">
                  {community.description}
                </p>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                align="start"
                className="tw-max-w-xs"
              >
                <div className="tw-max-h-[200px] tw-overflow-y-auto">
                  <p className="tw-text-left tw-whitespace-pre-wrap tw-break-words">
                    {community.description}
                  </p>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </CardContent>
        <CardFooter className="tw-pb-4">
          <p className="tw-text-sm tw-text-gray-500">
            {community.followerCount} Followers
          </p>
        </CardFooter>
      </Card>
    );
  }
);

export default CommunityCard;
