import { motion } from "framer-motion";
import { Card, CardContent } from "../shadcn-components/card";

interface InfoCardProps {
  heading: string;
  description: string;
}
const InfoCard = ({ heading, description }: InfoCardProps) => (
  <Card className="tw-bg-gray-50 tw-shrink-0 tw-w-[180px] tw-h-[90px] sm:tw-w-[260px] sm:tw-h-32 tw-border-custom-border">
    <CardContent className="!tw-p-3 sm:!tw-p-4 tw-flex tw-flex-col tw-items-start tw-justify-center tw-h-full">
      <h2 className="tw-font-bold tw-text-sm sm:tw-text-lg tw-mb-1">
        {heading}
      </h2>
      <p className="tw-text-gray-600 tw-text-xs tw-text-left">{description}</p>
    </CardContent>
  </Card>
);

const AnimatedInfoCards = () => {
  const CARDS_DATA = [
    {
      heading: "Join A Community",
      description: "Find your people, hang out, date, and level up.",
    },
    {
      heading: "Build A Community",
      description: "Connect offline, bring people together.",
    },
    {
      heading: "Monetize Your Passion",
      description: "Turn your community into a business.",
    },
  ];

  const duplicatedCards = [...CARDS_DATA, ...CARDS_DATA];

  return (
    <div className="tw-relative tw-w-full tw-overflow-hidden">
      <motion.div
        className="tw-flex tw-gap-3"
        animate={{
          x: [0, -780], // Adjust based on card width + gap
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        {duplicatedCards.map((card, index) => (
          <motion.div
            key={index}
            className="tw-flex-shrink-0"
            // whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <InfoCard heading={card.heading} description={card.description} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default AnimatedInfoCards;
