import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import {
  Community,
  communityStore,
  FollowerUser,
} from "../../stores/community-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { Button } from "../../shadcn-components/button";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "../../shadcn-components/card";
import { exploreStore } from "../../stores/explore-store";
import { getCommunityBanner } from "../../utils/image-utils";
import { getUsersPictures } from "../../utils/community-utils";
import { AvatarCircles } from "./avatar-circles-with-followers";

export const LandingPageCommunitiesSection: FC = observer(() => {
  const {
    exploreCommunities,
    isAdminCommunitiesLoading,
    followersByCommunityId,
  } = communityStore;
  const { selectCommunitiesTab } = exploreStore;
  useEffect(() => {
    const fetch = async () => {
      await communityStore.getCommunitiesByFilter();
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      await communityStore.getCommunityFollowers(
        exploreCommunities.map((c) => c.id)
      );
    };
    fetch();
  }, [exploreCommunities.length]);
  const navigate = useNavigate();

  const CommunityCardResponsive = ({ community }: { community: Community }) => {
    return (
      <>
        <div>
          <CommunityCardMobile
            community={community}
            followersByCommunityId={followersByCommunityId}
          />
        </div>
      </>
    );
  };

  return (
    <div className="tw-px-4 md:tw-px-12">
      <div className="tw-flex tw-justify-between tw-items-center">
        <h1 className="tw-font-bold tw-text-base sm:tw-text-lg tw-text-left">
          Popular Communities
        </h1>
        <Button
          variant="link"
          onClick={() => {
            selectCommunitiesTab();
            navigate("/explore");
          }}
          className="tw-text-primary tw-flex tw-items-center !tw-p-0"
        >
          View All
        </Button>
      </div>

      <div className="tw-mt-5">
        <div className="tw-relative">
          {isAdminCommunitiesLoading ? (
            <div className="tw-flex tw-justify-center tw-py-8">
              <LoadingSpinner />
            </div>
          ) : (
            <div
              className="
                tw-overflow-x-auto 
                tw-overflow-y-hidden
                tw-flex 
                md:tw-space-x-2
                tw-pb-2
                tw-relative
              "
            >
              {exploreCommunities.map((community) => (
                <div key={community.id} className="tw-flex-none">
                  <CommunityCardResponsive community={community} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const CommunityCardMobile: FC<{
  community: Community;
  followersByCommunityId: Map<string, FollowerUser[]>;
}> = observer(({ community, followersByCommunityId }) => {
  const navigate = useNavigate();
  const onCardClick = () => {
    navigate(`/${community.handle}`);
  };

  return (
    <Card
      className="tw-w-[120px] tw-max-w-md tw-border-transparent hover:tw-cursor-pointer !tw-shadow-none tw-mr-4"
      onClick={onCardClick}
    >
      <div className="tw-w-[120px] tw-h-[120px] tw-relative tw-rounded-lg tw-overflow-hidden">
        {community.bannerImageUrls ? (
          <img
            src={getCommunityBanner(community.bannerImageUrls)}
            alt={`${community.title} banner`}
            className="tw-w-full tw-h-full tw-object-cover"
          />
        ) : (
          <div className="tw-w-full tw-h-full tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-rounded-lg">
            <span className="tw-text-4xl tw-font-semibold tw-text-gray-400">
              {community.title.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
      </div>
      <CardContent className="!tw-p-0 !tw-pt-3">
        <h2 className="tw-text-sm tw-font-bold tw-mb-2 tw-text-left tw-line-clamp-2">
          {community.title}
        </h2>
        <div className="tw-flex tw-items-center tw-text-gray-600">
          <AvatarCircles
            pictures={getUsersPictures(community.id, followersByCommunityId)}
            followerCount={community.followerCount}
            onFollowersClick={() => {}}
            showFollowersText={false}
          />
        </div>
      </CardContent>
    </Card>
  );
});
