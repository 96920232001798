import { User } from "@/stores/auth.types";
import { apiClient } from "../../../stores/api-client";

export const usersApi = {
  getUser: async (): Promise<User> => {
    try {
      const data = await apiClient.get<User>(`/users`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      return data;
    } catch (error) {
      throw new Error(
        `Failed to get user: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    }
  },
}