import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from "mobx";
import { apiClient } from "./api-client";
import { ApiError, User } from "./auth.types";

class AuthStore {
  @observable user: User | null = null;
  @observable isLoading = false;
  @observable error: ApiError | null = null;
  @observable initialized = false;
  private isInitialCheck = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  initializeAuth = async (): Promise<void> => {
    if (this.initialized) return;

    try {
      this.isInitialCheck = true;
      await this.getUser();
    } catch (error) {
      runInAction(() => {
        this.user = null;
      });
    } finally {
      runInAction(() => {
        this.initialized = true;
        this.isInitialCheck = false;
      });
    }
  };

  @action
  refreshAuth = async (): Promise<void> => {
    try {
      await this.getUser();
    } catch (error) {
      runInAction(() => {
        this.user = null;
      });
    }
  };

  @action
  getUser = async (): Promise<void> => {
    try {
      this.isLoading = true;
      this.error = null;

      const data = await apiClient.get<User>("/users");

      if (data) {
        runInAction(() => {
          this.user = data;
        });
      }
    } catch (error) {
      runInAction(() => {
        if (!this.isInitialCheck) {
          this.error = error as ApiError;
        }
        this.user = null;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @computed
  get isAuthenticated(): boolean {
    return Boolean(this.user);
  }

  @action
  logout = async (): Promise<void> => {
    try {
      this.isLoading = true;
      this.error = null;
      await apiClient.post("/auth/logout");
      runInAction(() => {
        this.user = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error as ApiError;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  deleteUser = async () => {
    try {
      this.error = null;
      await apiClient.delete("/users");
      runInAction(() => {
        this.user = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error as ApiError;
      });
    }
  };
}

export const authStore = new AuthStore();
