import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "../../shadcn-components/input";
import { Button } from "../../shadcn-components/button";
import { Label } from "../../shadcn-components/label";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "../../shadcn-components/sheet";

const otpSchema = z.object({
  otp: z.string().length(6, "OTP must be 6 digits"),
});

type OTPFormValues = z.infer<typeof otpSchema>;

interface OTPVerificationProps {
  phoneNumber: string;
  onVerify: (otp: string) => Promise<void>;
  isVerifying?: boolean;
  showTimer?: boolean;
  initialTimerValue?: number;
  onResendOTP?: () => void;
  title?: string;
  description?: string;
}
interface OTPVerificationSheetProps extends OTPVerificationProps {
  isOpen: boolean;
  onClose: () => void;
}

// The core OTP verification form component
export const OTPVerificationForm: React.FC<OTPVerificationProps> = ({
  phoneNumber,
  onVerify,
  isVerifying = false,
  showTimer = true,
  initialTimerValue = 30,
  onResendOTP,
  title = "Verify your number",
  description = "Enter the verification code sent to the mobile number you have provided",
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTimerValue);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OTPFormValues>({
    resolver: zodResolver(otpSchema),
    defaultValues: {
      otp: "",
    },
  });

  useEffect(() => {
    if (!showTimer) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [showTimer]);

  const onSubmit = async (data: OTPFormValues) => {
    await onVerify(data.otp);
  };

  return (
    <div className="tw-space-y-6">
      {title && <h2 className="tw-text-lg tw-font-semibold">{title}</h2>}
      {description && (
        <p className="tw-text-sm tw-text-gray-500">{description}</p>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-6">
        <div className="space-y-2">
          <div className="tw-flex tw-justify-between tw-items-center">
            <Label>OTP</Label>
            <span className="tw-text-sm tw-text-gray-500">{phoneNumber}</span>
          </div>
          <Input
            {...register("otp")}
            type="text"
            inputMode="numeric"
            maxLength={6}
            className="tw-text-lg tw-tracking-[1em]"
            placeholder="------"
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, "");
              e.target.value = value;
            }}
          />
          {errors.otp && (
            <p className="tw-text-red-500 tw-text-sm">{errors.otp.message}</p>
          )}
        </div>

        {showTimer && (
          <div className="tw-flex tw-justify-between tw-items-center tw-text-sm">
            <span className="text-gray-500">
              This text should arrive within{" "}
              <span className="tw-text-blue-600">{timeLeft}s</span>
            </span>
            {timeLeft === 0 && onResendOTP && (
              <Button
                type="button"
                variant="link"
                className="tw-text-blue-600 tw-p-0"
                onClick={onResendOTP}
              >
                Resend OTP
              </Button>
            )}
          </div>
        )}

        <Button
          type="submit"
          disabled={isVerifying}
          className="tw-w-full tw-bg-blue-600 tw-hover:bg-blue-700 tw-text-white tw-py-6 tw-rounded-[38px]"
        >
          {isVerifying ? "Verifying..." : "Verify"}
        </Button>
      </form>
    </div>
  );
};

export const OTPVerificationSheet: React.FC<OTPVerificationSheetProps> = ({
  isOpen,
  onClose,
  ...otpProps
}) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent side="bottom" className="!tw-bg-white tw-h-[80vh] tw-rounded-t-3xl">
        <SheetHeader>
          <SheetTitle>{otpProps.title}</SheetTitle>
          <SheetDescription>{otpProps.description}</SheetDescription>
        </SheetHeader>
        <div className="mt-6">
          <OTPVerificationForm {...otpProps} />
        </div>
      </SheetContent>
    </Sheet>
  );
};
