import { FC, useEffect, useState } from "react";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "../../shadcn-components/avatar";
import { manageCommunityStore } from "../../stores/manage-community.store";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
import { Input } from "../../shadcn-components/input";
import { getUserImage } from "../../utils/image-utils";

export const ManageAdmins: FC = observer(() => {
  const [searchQuery, setSearchQuery] = useState("");
  const {
    searchUsers,
    searchResults,
    isSearchingUser,
    clearSearchResults,
    addAdmin,
  } = manageCommunityStore;
  const { communityHandle } = useParams<{ communityHandle: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchQuery.trim()) {
        await searchUsers(searchQuery);
      } else {
        clearSearchResults();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery, searchUsers, clearSearchResults]);

  const handleSelectUser = async (userId: string) => {
    await addAdmin(userId);
    navigate(`/${communityHandle}/manage`, { state: { tab: "admin" } });
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-white">
      <div className="tw-flex tw-flex-col tw-p-6 tw-space-y-6">
        <div className="tw-space-y-2">
          <h2 className="tw-text-base tw-font-medium tw-text-left">
            Username<span className="tw-text-red-500">*</span>
          </h2>

          <Input
            type="text"
            placeholder="Search user..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-3xl tw-text-base"
          />
        </div>

        {searchQuery && (
          <div className="tw-space-y-4">
            {isSearchingUser ? (
              <div className="tw-flex tw-justify-center">
                <LoadingSpinner />
              </div>
            ) : (
              searchResults.map((user) => (
                <div
                  key={user.userId}
                  onClick={() => handleSelectUser(user.userId)}
                  className="tw-flex tw-items-center tw-p-3 tw-cursor-pointer hover:tw-bg-gray-50"
                >
                  <Avatar className="tw-h-12 tw-w-12">
                    {user.profilePicture && (
                      <AvatarImage
                        src={
                          user.profilePicture
                            ? getUserImage(user.profilePicture)
                            : ""
                        }
                      />
                    )}
                    <AvatarFallback>
                      {user.accountName.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="tw-ml-3">
                    <div className="tw-font-medium">{user.accountName}</div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
});
