import { FC } from "react";
import { Button } from "../../shadcn-components/button";

import jackpassLogo from "../../static/logo.png";
import jackpassText from "../../static/jackpass-text.png";
import igIcon from "../../static/instagram.png";
import linkedinIcon from "../../static/linkedin.png";

interface FooterProps {
  hideSocials?: boolean;
}

export const Footer: FC<FooterProps> = ({ hideSocials = false }) => {
  const textStyles = "hover:tw-text-gray-600 tw-text-left sm:tw-text-center tw-text-sm";
  return (
    <footer className="tw-bg-white tw-py-8 tw-px-6">
      <div className="tw-max-w-6xl tw-mx-auto">
        <div className="tw-flex tw-items-center tw-gap-1 tw-mb-8 sm:tw-justify-center">
          <img src={jackpassLogo} alt="Jackpass Logo" className="tw-h-8" />
          <img src={jackpassText} alt="Jackpass" className="tw-h-6" />
        </div>

        <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-center tw-items-start sm:tw-items-center">
          <nav className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-gap-8 tw-gap-5 tw-mb-6 sm:tw-mb-0">
            <a href="/about-us" className={textStyles}>
              About Us
            </a>
            <a href="/terms" className={textStyles}>
              Terms & Conditions
            </a>
            <a href="/privacy" className={textStyles}>
              Privacy Policy
            </a>
            <a href="/contact" className={textStyles}>
              Contact Us
            </a>
          </nav>
          {!hideSocials && (
            <div className="tw-flex tw-gap-4 sm:tw-ml-8">
              <Button
                variant="ghost"
                size="icon"
                className="hover:tw-bg-transparent tw-h-6 tw-w-6"
              >
                <img src={igIcon} alt="instagram" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="hover:tw-bg-transparent tw-h-6 tw-w-6"
              >
                <img src={linkedinIcon} alt="LinkedIn" />
              </Button>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};
