import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { User } from "./auth.types";
import { usersApi } from "../components/user/api/user.api";

interface ProfileData {
  userId: string;
  accountName: string;
  dateOfBirth: string;
  phoneNumber: string;
  cities?: string[];
  bio?: string;
}

type UpdateFieldKey = keyof Omit<ProfileData, "userId">;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export interface UpdateStatus {
  field: UpdateFieldKey;
  status: "saving" | "success" | "error" | "verifying";
  message?: string;
}
class ProfileSettingsStore {
  @observable isLoading = false;
  @observable editProfileData: User | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  @action
  updateProfile = async (data: Partial<ProfileData>) => {
    this.isLoading = true;

    try {
      const response = await axios.post(`${apiBaseUrl}/users`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        return true;
      }
    } catch (error: any) {
      console.error("Error updating profile:", error);
      return false;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  getEditProfileData = async () => {
    this.isLoading = true;
    try {
      const data = await usersApi.getUser();
      runInAction(() => {
        this.editProfileData = data;
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  deleteUser = async () => {
    try {
      const response = await axios.delete(`${apiBaseUrl}/users`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
}

const profileSettingsStore = new ProfileSettingsStore();
export default profileSettingsStore;
